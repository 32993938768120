import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

import { put, takeLatest } from 'redux-saga/effects';
import { getGroupsAsync, updateGroupAsync, createGroupAsync, deleteGroupAsync } from './groupsCrud';
export const actionTypes = {
  GetGroups: 'GET__GROUPS',
  GetGroupsAsync: 'GET__GROUPS_ASYNC',
  GetGroupsCompleted: 'GET__GROUPS_COMPLETED',
  SetStatusGroups: 'SET__STATUS__GROUPS',


  UpdateGroup: 'UPDATE__GROUP',
  UpdateGroupCompleted: 'UPDATE__GROUP_COMPLETED',
  SetStatusUpdateGroups: 'UPDATE__STATUS__GROUP',

  CreateGroup: 'CREATE__GROUP',
  CreateGroupCompleted: 'CREATE__GROUP_COMPLETED',
  SetStatusCreateGroups: 'CREATE__STATUS__GROUP',

  DeleteGroup: 'DELETE__GROUP',
  DeleteGroupCompleted: 'DELETE__GROUP_COMPLETED',
  SetStatusDeleteGroups: 'DELETE__STATUS__GROUP',


  SetGroupsGroup: 'SET__GROUPS_GROUP',

};

// #####################################################
// #####################################################

const initialGroupState = {
  statusFetch: 'initial',
  list: {
    data: [],
  },
  last_group: {},
  group: null

};

// #####################################################
// #####################################################

export const reducer = persistReducer(
  { storage, key: 'cl-groups', whitelist: ['statusFetch', 'list'] },
  (state = initialGroupState, action) => {
    switch (action.type) {
      case actionTypes.SetStatusGroups: {
        const { status } = action.payload;

        return {
          ...state,
          statusFetch: status,
        };
      }

      case actionTypes.GetGroupsCompleted: {
        const { groups } = action.payload;
        return {
          ...state,
          list: groups.data,
          statusFetch: 'success',
        };
      }


      case actionTypes.UpdateGroupCompleted: {
        const { group } = action.payload;
        const index = state.list.data.findIndex(item => item.id === group.data.id)
        if (index >= 0) {
          state.list.data[index] = group.data
        }
        return {
          ...state,

          group: group.data,
          //statusFetch: 'success',
        };
      }


      case actionTypes.CreateGroupCompleted: {
        const { group } = action.payload;
        return {
          ...state,
          group: group.data,
          //statusFetch: 'success',
        };
      }


      case actionTypes.DeleteGroupCompleted: {

        return {
          ...state,

          //statusFetch: 'success',
        };
      }


      case actionTypes.SetGroupsGroup: {
        const { group } = action.payload;

        return {
          ...state,
          group: group,
        };
      }

      default:
        return state;
    }
  }
);

// #####################################################
// #####################################################

export const actions = {
  // ------------------------------------------------------------
  requestGroups: () => {
    return { type: actionTypes.GetGroups };
  },
  requestGroupsAsync: () => {
    return { type: actionTypes.GetGroupsAsync };
  },
  requestGroupsCompleted: function (groups) {
    return { type: actionTypes.GetGroupsCompleted, payload: { groups } };
  },
  setStatusGroups: (status) => {
    return { type: actionTypes.SetStatusGroups, payload: { status } };
  },

  // ------------------------------------------------------------

  requestUpdateGroups: (group) => {
    return { type: actionTypes.UpdateGroup, payload: { group } };
  },
  requestUpdateGroupCompleted: function (group) {
    return { type: actionTypes.UpdateGroupCompleted, payload: { group } };
  },
  setStatusUpdateGroup: (status) => {
    return { type: actionTypes.SetStatusUpdateGroups, payload: { status } };
  },


  // ------------------------------------------------------------

  requestDeleteGroups: (group) => {
    return { type: actionTypes.DeleteGroup, payload: { group } };
  },
  requestDeleteGroupCompleted: function (group) {
    return { type: actionTypes.DeleteGroupCompleted, payload: { group } };
  },
  setStatusDeleteGroup: (status) => {
    return { type: actionTypes.SetStatusDeleteGroups, payload: { status } };
  },



  // ------------------------------------------------------------

  requestCreateGroups: (group) => {
    return { type: actionTypes.CreateGroup, payload: { group } };
  },
  requestCreateGroupCompleted: function (group) {
    return { type: actionTypes.CreateGroupCompleted, payload: { group } };
  },
  setStatusCreateGroup: (status) => {
    return { type: actionTypes.SetStatusCreateGroups, payload: { status } };
  },

  setGroupsGroup: (group) => {
    return {
      type: actionTypes.SetGroupsGroup,
      payload: { group },
    };
  },
};

// #####################################################
// #####################################################

export function* saga() {
  //listo group
  yield takeLatest(actionTypes.GetGroups, function* getGroupsSaga(action) {
    yield put(actions.setStatusGroups('initial'));
    try {
      const groups = yield getGroupsAsync();
      yield put(actions.requestGroupsCompleted(groups));
    } catch {
      yield put(actions.setStatusGroups('fail'));
    }
  });


  yield takeLatest(actionTypes.GetGroupsAsync, function* getGroupsAsyncSaga(action) {

    try {
      const groups = yield getGroupsAsync();
      yield put(actions.requestGroupsCompleted(groups));
    } catch {

    }
  });


  //--update group
  yield takeLatest(actionTypes.UpdateGroup, function* getUpdateGroupSaga(data) {
    yield put(actions.setStatusUpdateGroup('initial'));
    try {
      const group = yield updateGroupAsync(data.payload.group);
      yield put(actions.requestUpdateGroupCompleted(group));
    } catch {
      yield put(actions.setStatusUpdateGroup('fail'));
    }
  });


  //--create group
  yield takeLatest(actionTypes.CreateGroup, function* getCreateGroupSaga(data) {
    yield put(actions.setStatusCreateGroup('initial'));
    try {
      const group = yield createGroupAsync(data.payload.group);
      yield put(actions.requestCreateGroupCompleted(group));
    } catch {
      yield put(actions.setStatusCreateGroup('fail'));
    }
  });

  //--delete group
  yield takeLatest(actionTypes.DeleteGroup, function* getDeleteGroupSaga(data) {
    yield put(actions.setStatusDeleteGroup('initial'));
    try {
      const group = yield deleteGroupAsync(data.payload.group);
      yield put(actions.requestDeleteGroupCompleted(group));
    } catch {
      yield put(actions.setStatusDeleteGroup('fail'));
    }
  });
}


