

import { put, takeLatest } from 'redux-saga/effects';
import { getInvoicesAsync, requestUpdateInvoicesAsync, getInvoiceAsync, getCompanyInvoicesAsync } from './invoicesCrud';
export const actionTypes = {
  GetInvoices: 'GET__INVOICES',
  GetInvoicesCompleted: 'GET__INVOICES_COMPLETED',
  SetStatusInvoices: 'SET__STATUS__INVOICES',

  GetCompanyInvoices: 'GET__COMPANY_INVOICES',
  GetCompanyInvoicesCompleted: 'GET__COMPANY_INVOICES_COMPLETED',
  SetStatusCompanyInvoices: 'SET__STATUS__COMPANY_INVOICES',

  GetInvoice: 'GET__INVOICE',
  GetInvoiceCompleted: 'GET__INVOICE_COMPLETED',
  SetStatusInvoice: 'SET__STATUS__INVOICE',


  SetInvoicesFilterSortBy: 'SET__INVOICES__FILTER_SORTBY',
  SetInvoicesFilterSearchBy: 'SET__INVOICES__FILTER_SEARCHBY',
  SetInvoicesFilterByDate: 'SET__INVOICES__FILTER_BYDATE',
  SetInvoicesFilterLayoutView: 'SET__INVOICES__FILTER_LAYOUTVIEW',

  RequestUpdateInvoices: 'REQUEST_UPDATE_INVOICES',
  UpdateInvoicesCompleted: 'REQUEST_UPDATE_INVOICES_COMPLETED',
  SetUpdateStatusInvoices: 'SET__UPDATE_STATUS__INVOICES',

};

// #####################################################
// #####################################################

const initialCompanyState = {
  statusFetch: 'initial',
  statusCompanyFetch: 'initial',


  updateStatusFetch: '',
  list: {
    data: []
  },
  company_list: {
    data: []
  },
  sortBy: '',
  searchBy: '',
  filterByDate: new Date().toISOString().substr(0, 10),
  layoutView: 'grid',

  statusFetchInvoice: 'initial',
  invoice: null

};

// #####################################################
// #####################################################

export const reducer = /*persistReducer(
  { storage: storageSession, key: 'cl-invoices', whitelist: ['list'] },
  */(state = initialCompanyState, action) => {
    switch (action.type) {
      case actionTypes.SetStatusInvoices: {
        const { status } = action.payload;

        return {
          ...state,
          statusFetch: status,
        };
      }

      case actionTypes.SetStatusCompanyInvoices: {
        const { status } = action.payload;

        return {
          ...state,
          statusCompanyFetch: status,
        };
      }

      case actionTypes.SetStatusInvoice: {
        const { status } = action.payload;

        return {
          ...state,
          statusFetchInvoice: status,
        };
      }


      case actionTypes.SetUpdateStatusInvoices: {
        const { status } = action.payload;

        return {
          ...state,
          updateStatusFetch: status,
        };
      }


      case actionTypes.GetCompanyInvoicesCompleted: {
        const { invoices } = action.payload;
        const list = invoices.data;

        list.data = list.data.map(item => {
          item.name = "";
          if (item.first_name)
            item.name = (item.first_name + " " + item.last_name).trim();
          return item;
        }).sort((i, o) => {
          return i.invoice_date > o.invoice_date ? -1 : 1
        });


        return {
          ...state,
          company_list: list,
          statusCompanyFetch: 'success',
        };
      }


      case actionTypes.GetInvoicesCompleted: {
        const { invoices } = action.payload;
        const list = invoices.data;

        list.data = list.data.map(item => {
          item.name = "";
          if (item.first_name)
            item.name = (item.first_name + " " + item.last_name).trim();

          try {
            //if(item.sender_name.indexOf( 'AFYA')>=0) item.sender_name = 'COMPANY NAME';
            //if(item.receiver_name.indexOf( 'AFYA')>=0) item.receiver_name = 'COMPANY NAME';

            //item.reference = item.reference;//.replace('AFYA', '')
          } catch (ex) { }

          return item;
        }).sort((i, o) => {
          return i.invoice_date > o.invoice_date ? -1 : 1
        });


        return {
          ...state,
          list,
          statusFetch: 'success',
        };
      }


      case actionTypes.GetInvoiceCompleted: {
        const { invoice } = action.payload;

        const item = invoice.data
        try {
          //if (item.sender_name.indexOf('AFYA') >= 0) item.sender_name = 'COMPANY NAME';
          //if (item.receiver_name.indexOf('AFYA') >= 0) item.receiver_name = 'COMPANY NAME';

          //item.reference = item.reference.replace('AFYA', '')
        } catch (ex) { }


        return {
          ...state,
          invoice: item,
          statusFetchInvoice: 'success',
        };
      }



      case actionTypes.SetInvoicesFilterSortBy: {
        const { sortBy } = action.payload;

        return {
          ...state,
          sortBy: sortBy,
        };
      }
      case actionTypes.SetInvoicesFilterSearchBy: {
        const { searchBy } = action.payload;

        return {
          ...state,
          searchBy: searchBy,
        };
      }


      case actionTypes.SetInvoicesFilterByDate: {
        const { filterByDate } = action.payload;

        return {
          ...state,
          filterByDate: filterByDate,
        };
      }

      case actionTypes.SetInvoicesFilterLayoutView: {
        const { layoutView } = action.payload;

        return {
          ...state,
          layoutView: layoutView,
        };
      }



      case actionTypes.UpdateInvoicesCompleted: {
        const { card } = action.payload;
        const cardItem = card.data;
        if (cardItem.object === 'card') {
          state.list.data.forEach((item, index) => {
            if (item.id === cardItem.id) {
              cardItem.name = "";
              if (cardItem.first_name)
                cardItem.name = (cardItem.first_name + " " + cardItem.last_name).trim();
              state.list.data[index] = cardItem;
            }
          });
        }
        return {
          ...state,
          updateStatusFetch: 'success',
        };
      }


      default:
        return state;
    }
  }
//);

// #####################################################
// #####################################################

export const actions = {
  // ------------------------------------------------------------
  requestInvoices: () => {
    return { type: actionTypes.GetInvoices };
  },
  requestInvoicesCompleted: function (invoices) {
    return { type: actionTypes.GetInvoicesCompleted, payload: { invoices } };
  },
  setStatusInvoices: (status) => {
    return { type: actionTypes.SetStatusInvoices, payload: { status } };
  },

  // ------------------------------------------------------------
  requestCompanyInvoices: () => {
    return { type: actionTypes.GetCompanyInvoices };
  },
  requestCompanyInvoicesCompleted: function (invoices) {
    return { type: actionTypes.GetCompanyInvoicesCompleted, payload: { invoices } };
  },
  setStatusCompanyInvoices: (status) => {
    return { type: actionTypes.SetStatusCompanyInvoices, payload: { status } };
  },


  // ------------------------------------------------------------
  requestInvoice: ({ id }) => {
    return { type: actionTypes.GetInvoice, payload: { id } };
  },
  requestInvoiceCompleted: function (invoice) {
    return { type: actionTypes.GetInvoiceCompleted, payload: { invoice } };
  },
  setStatusInvoice: (status) => {
    return { type: actionTypes.SetStatusInvoice, payload: { status } };
  },



  setFilterSortBy: (sortBy) => {
    return { type: actionTypes.SetInvoicesFilterSortBy, payload: { sortBy } };
  },
  setFilterSearchBy: (searchBy) => {
    return { type: actionTypes.SetInvoicesFilterSearchBy, payload: { searchBy } };
  },
  setFilterLayoutView: (layoutView) => {
    return { type: actionTypes.SetInvoicesFilterLayoutView, payload: { layoutView } };
  },
  setFilterByDate: (filterByDate) => {
    return { type: actionTypes.SetInvoicesFilterByDate, payload: { filterByDate } };
  },




  requestUpdateInvoices: (card) => {
    return { type: actionTypes.RequestUpdateInvoices, payload: { card } };
  },
  requestUpdateInvoicesCompleted: function (card) {
    return { type: actionTypes.UpdateInvoicesCompleted, payload: { card } };
  },
  setUpdateStatusInvoices: (status) => {
    return { type: actionTypes.SetUpdateStatusInvoices, payload: { status } };
  },

};

// #####################################################
// #####################################################

export function* saga() {
  yield takeLatest(actionTypes.GetInvoices, function* getInvoicesSaga(action) {
    yield put(actions.setStatusInvoices('initial'));
    try {
      const company = yield getInvoicesAsync();
      yield put(actions.requestInvoicesCompleted(company));
    } catch {
      yield put(actions.setStatusInvoices('fail'));
    }
  });

  yield takeLatest(actionTypes.GetCompanyInvoices, function* getCompanyInvoicesSaga(action) {
    yield put(actions.setStatusCompanyInvoices('initial'));
    try {
      const company = yield getCompanyInvoicesAsync();
      yield put(actions.requestCompanyInvoicesCompleted(company));
    } catch {
      yield put(actions.setStatusCompanyInvoices('fail'));
    }
  });



  yield takeLatest(actionTypes.GetInvoice, function* getInvoice(action) {
    yield put(actions.setStatusInvoice('initial'));
    try {
      const invoice = yield getInvoiceAsync(action.payload.id);
      yield put(actions.requestInvoiceCompleted(invoice));
    } catch {
      yield put(actions.setStatusInvoice('fail'));
    }
  });



  yield takeLatest(actionTypes.RequestUpdateInvoices, function* requestUpdateInvoicesSaga(action) {
    yield put(actions.setUpdateStatusInvoices('initial'));

    try {
      const invoices = yield requestUpdateInvoicesAsync(action.payload.card);
      yield put(actions.requestUpdateInvoicesCompleted(invoices));
    } catch (ex) {
      yield put(actions.setUpdateStatusInvoices('fail'));
    }
  });
}
