import { getUsers, createUser, updateUser } from '../../../provider/api/core'

export function getUsersAsync() {
    return getUsers();
}
 


export function createUserAsync(params) {
    return createUser(params);
}

export function updateUserAsync(params) {
    return updateUser(params);
}