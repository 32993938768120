// ---------------------------------------------
// Auth
// ---------------------------------------------

export const LoggedInEvent = (data) => {
    window.analytics.identify(data.username, {
        email: data.email,
        email_verified:data.email_verified,
        phone_number: data.phone_number,
    });
}
export const LoggingInEvent = (email) => {
    window.analytics.track("AUTH_LOGGING_IN", {
        email: email
    });
}
export const LoggedInFailedEvent = (email, error) => {
    window.analytics.track("AUTH_LOGGED_IN_FAILED", {
        email: email,
        error: error
    });
}

export const LoggedOutEvent = (email) => {
    window.analytics.track("AUTH_LOGOUT", {
        email: email,
    });
    window.analytics.reset();

}




export const ResetPasswordEvent = ({email, data}) => {
    window.analytics.track("AUTH_RESET_PASSWORD", {
        email: email,
        data: data
    });
}
export const ResetPasswordErrorEvent = ({email, error}) => {
    window.analytics.track("AUTH_RESET_PASSWORD_ERROR", {
        email: email,
        error: error
    });
}

export const ResetPasswordConfirmEvent = ({email, data}) => {
    window.analytics.track("AUTH_RESET_PASSWORD_CONFIRM", {
        email: email,
        data: data
    });
}
export const ResetPasswordConfirmErrorEvent = ({email, error}) => {
    window.analytics.track("AUTH_RESET_PASSWORD_CONFIRM_ERROR", {
        email: email,
        error: error
    });
}