const Resource = require('../Resource');

module.exports = Resource.extend({
  list() {
    return this.prepare().get(`companies/`);
  },
  retrieve(id) {
    return this.prepare().get(`companies/${id}`);
  }, 
  update(id, body) {
    return this.prepare().post(`companies/${id}`, body);
  },
});
