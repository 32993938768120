const Resource = require('../Resource');

module.exports = Resource.extend({
  list(params) {
    const master = sessionStorage.getItem('master');

    const URL = `transactions/${master}${this.jsonToQueryString(params)}`;
    return this.prepare().get(URL);
  },

  retrieve(id) {
    const master = sessionStorage.getItem('master');

    return this.prepare().get(`transactions/${master}/${id}`);
  },
  update(id, body) {
    const master = sessionStorage.getItem('master');
    return this.prepare().post(`transactions/${master}/${id}`, body);
  },
});
