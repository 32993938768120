import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as auth from '../_redux/authRedux';
import * as user from '../../..//modules/UserProfile/_redux/userRedux';
import { LoggedOutEvent } from '../../../provider/segment/events/auth';

import * as Sentry from '@sentry/react';
import resetCrispScript from '../../../../layout/footer/closechat';

const Logout = (props) => {
	const navigate = useNavigate();

	console.log('Logout');
	const { authToken, logout, deleteInitialData, deleteAuthInitialData, email } =
		props;

	useEffect(() => {
		const tour = localStorage.getItem('tour');

		deleteInitialData();
		deleteAuthInitialData();
		try {
			logout();
		} catch (ex) { }

		LoggedOutEvent(email);

		Sentry.setUser(null);

		localStorage.clear();
		sessionStorage.clear();

		if (tour && tour !== 'null') localStorage.setItem('tour', tour);

		resetCrispScript();
	});

	const loggedIn = authToken && Object.keys(authToken).length > 0;
	if (!loggedIn) {
		navigate('/auth/login');
		return <></>;
	}

	return <>...</>;
};

export default connect(
	({ auth, user }) => {
		return {
			authToken: auth.authToken,
			email: user.email,
			id: user.uuid,
		};
	},
	{ ...auth.actions, ...user.actions }
)(Logout);
