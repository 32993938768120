//@@ const categoryNames = require('../../../../data/categories.json');

const now = () => {
  return new Date().toISOString().substr(0, 7);
};

export const transactionsSummary = (date, transactions) => {
  const NOW = now();

  let summary = {
    start_date: date.substr(0, 7),

    debit: 0,
    charges: 0,
    deposit: 0,
    operations: 0,

    category: {},
    cards: {},
    merchants: {},
    status: {},
    dates: {},
    transactions: [],
    initial_amount: 0,
    final_amount: 0,
  };

  summary.dates = fillDates(date);

  const today = {
    date: NOW,
    debit: 0,
    charges: 0,
    deposit: 0,
    operations: 0,
    transactions: [],
  };

  const data = transactions.data;
  const list = ['COBRO POR SISTEMA', 'CARGO POR SISTEMA', 'PAGO POR TRANSFERENCIA'];


  for (var j in data) {
    var row = data[j];
    row.date_num = row.date.replace(/-/gi, '');

    row.status = row.status === 'debit' ? 'debit' : row.status;

    let cat =
      row.merchant && row.merchant.category ? row.merchant.category : '-';
    /**
    let catName = cat;
    let st = row.status; // statusName[row.status]
    let c = categoryNames.find((i) => i.id === cat);
    if (c) {
      catName = c.name.toUpperCase();
    }*/

    // console.log(row)

    if (row.status !== 'rejected') {
      //if (j < 5) {
      summary.transactions.push(row);
      //}

      let crd = row.card;
      let merchant =
        row.original_description && row.original_description !== ''
          ? row.original_description.toUpperCase()
          : (row.description !== '' ? row.description.toUpperCase() : '_');

      if (row.status !== 'deposit') {
        if (!summary.category[cat])
          summary.category[cat] = { amount: 0.0, count: 0.0 };
        summary.category[cat].count = summary.category[cat].count + 1;
        summary.category[cat].amount =
          summary.category[cat].amount + row.amount;

        if (!summary.merchants[merchant])
          summary.merchants[merchant] = { amount: 0.0, count: 0.0 };
        summary.merchants[merchant].count =
          summary.merchants[merchant].count + 1;
        summary.merchants[merchant].amount =
          summary.merchants[merchant].amount + row.amount;
        if (row.merchant) {
          if (row.merchant.icon) {
            summary.merchants[merchant].icon = row.merchant.icon;
          }
          if (row.merchant.category) {
            summary.merchants[merchant].category = row.merchant.category;
          }
        }
      }

      let sts = row.status === 'pending' ? 'debit' : row.status;
      row.amount = parseFloat(row.amount);
      summary[sts] = summary[sts] + row.amount;

      let system = false;
      if (row.original_description && list.indexOf(row.original_description) >= 0) system = true;
      if (row.description && list.indexOf(row.description) >= 0) system = true;


      if (sts === 'debit') {
        if (system) {
          summary['charges'] = summary['charges'] + row.amount;
        }
      }



      summary.operations = summary.operations + 1;

      if (row.date === NOW) {
        today[sts] = today[sts] + row.amount;
        if (sts === 'debit') {
          if (system) {
            today['charges'] = today['charges'] + row.amount;
          }
        }

        today.operations = today.operations + 1;

        today.transactions.push(row);
      }

      //row.status = statusName[sts]
      if (!summary.status[sts])
        summary.status[sts] = { amount: 0.0, count: 0.0 };
      summary.status[sts].count = summary.status[sts].count + 1;
      summary.status[sts].amount = summary.status[sts].amount + row.amount;

      if (!summary.dates[row.date])
        summary.dates[row.date] = { deposit: 0.0, debit: 0.0 };
      summary.dates[row.date][sts] = summary.dates[row.date][sts] + row.amount;

      if (!summary.cards[crd])
        summary.cards[crd] = { debit: 0.0, deposit: 0.0 };
      summary.cards[crd][sts] = summary.cards[crd][sts] + row.amount;
    }
  }

  let SortFunc = (ALL) => {
    return Object.fromEntries(
      Object.entries(ALL).sort(([, a], [, b]) => b.amount - a.amount)
    );
  };

  let SortArrayFunc = (ALL) => {
    return ALL.sort((a, b) => b.date_num - a.date_num);
  };

  let SortByCardFunc = (ALL) => {
    return Object.fromEntries(
      Object.entries(ALL).sort(([, a], [, b]) => b.debit - a.debit)
    );
  };

  //sort-->
  summary.category = SortFunc(summary.category);
  summary.cards = SortByCardFunc(summary.cards);
  summary.merchants = SortFunc(summary.merchants);
  summary.status = SortFunc(summary.status);
  summary.debit = parseFloat(summary.debit).toFixed(2);
  summary.charges = parseFloat(summary.charges).toFixed(2);
  summary.deposit = parseFloat(summary.deposit).toFixed(2);

  summary.transactions = SortArrayFunc(summary.transactions);
  summary.transactions = summary.transactions.slice(0, 10);

  if (NOW.substr(0, 7) === summary.start_date.substr(0, 7)) {
    today.transactions = today.transactions.slice(0, 10);
    summary.today = today;
  }

  return summary;
};

const fillDates = (date) => {
  var dt = new Date(date.substr(0, 7) + '-01T01:00:00');

  let x = {};
  var month = dt.getMonth();

  while (month === dt.getMonth()) {
    x[dt.toISOString().substring(0, 10)] = { deposit: 0.0, debit: 0.0 };
    dt.setDate(dt.getDate() + 1);
  }
  return x;
};
