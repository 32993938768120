import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getUserByToken } from './userCrud';
//->import * as roleRoutes from "../../../../app/routes/index"

export const actionTypes = {
  SetUserInitialData: 'SET__USER__INITIAL_DATA',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  GetProfileUser: 'GET__USER__PROFILE',
  DeleteUserInitialData: 'DELETE__USER__INITIAL__DATA',
  GetProfileUserCompleted: 'GET__USER__PROFILE__COMPLETED',
};

const initialUserState = {
  name: '',
  email: '',
  username: '',
  status: 'initial',
  privileges: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: 'cl-user',
    whitelist: ['role', 'name', 'email', 'uuid', 'username', 'companyUuid', 'privileges'],
  },
  (state = initialUserState, action) => {
    switch (action.type) {
      case actionTypes.SetUserInitialData: {
        const { user } = action.payload;

        delete user.sectionsActions;

        if (user['cognito:username']) user['username'] = user['cognito:username'];

        return {
          ...state,
          ...user,
          username: user['username'],
          role: 'USER',
          privileges: [
            {
              name: 'DASHBOARD',
              privileges: ['SEARCH'],
              order: 1,
            },
            {
              name: 'COMPANY',
              privileges: ['SEARCH'],
              order: 2,
            },
            {
              name: 'CARDS',
              privileges: [
                'SEARCH',
                'VIEW',
                'CREATE',
                'UPDATE',
                'DELETE',
                'TEAM',
              ],
              order: 3,
            },
            {
              name: 'USERS',
              privileges: [
                'SEARCH',
                'VIEW',
                'CREATE',
                'UPDATE',
                'DELETE',
                'TEAM',
              ],
              order: 4,
            },
            {
              name: 'ORDERS',
              privileges: [
                'SEARCH',
                'VIEW',
                'CREATE',
                'UPDATE',
                'DELETE',
                'TEAM',
              ],
              order: 4,
            },

            {
              name: 'GROUPS',
              privileges: [
                'SEARCH',
                'VIEW',
                'CREATE',
                'UPDATE',
                'DELETE',
                'TEAM',
              ],
              order: 5,
            },


            {
              name: 'TRANSACTIONS',
              privileges: [
                'SEARCH',
                'VIEW',
                'CREATE',
                'UPDATE',
                'DELETE',
                'TEAM',
              ],
              order: 5,
            },

            {
              name: 'TRANSFERENCES',
              privileges: [
                'SEARCH',
                'VIEW',
                'CREATE',
                'UPDATE',
                'DELETE',
                'TEAM',
              ],
              order: 5,
            },

            {
              name: 'INVOICES',
              privileges: [
                'SEARCH',
                'VIEW',
                'CREATE',
                'UPDATE',
                'DELETE',

              ],
              order: 6,
            },
            {
              name: 'SERVICES',
              privileges: [
                'SEARCH',
                'VIEW',
                'CREATE',
                'UPDATE',
                'DELETE',

              ],
              order: 7,
            },
          ], // roleRoutes[user.role + "_ROUTES"]
        };
      }
      case actionTypes.DeleteUserInitialData: {
        return initialUserState;
      }
      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        delete user.role; //TODO
        return {
          ...state,
          ...user,
          status: 'success',
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setInitialData: (user) => ({
    type: actionTypes.SetUserInitialData,
    payload: { user },
  }),
  requestUser: (idUser) => ({
    type: actionTypes.UserRequested,
    payload: { idUser },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  deleteInitialData: () => ({ type: actionTypes.DeleteUserInitialData }),
};

export function* saga() {
  yield takeLatest(actionTypes.UserRequested, function* userRequested(action) {
    try {
      const { data: user } = yield getUserByToken(action.payload.idUser);
      yield put(actions.fulfillUser(user));
    } catch {

    }
  });
}
