import { getTransactions,updateTransaction } from '../../../provider/api/core'

export function getTransactionsAsync(payload) {
    return getTransactions(payload);
}
 

export function updateTransactionAsync(payload) {
    return updateTransaction(payload);
}
 