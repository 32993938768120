import React, { Fragment, useState, useLayoutEffect, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Menu } from 'react-feather';
import logo_icon from '../../assets/images/logo/logo120x120.png';
import CompaniesComponent from '../../app/modules/Dashboard/pages/commpaniesComponent'; //'../../app/modules/Dashbboard/pages/inf';

const Leftbar = (props) => {
  const [sidebartoggle, setSidebartoggle] = useState(false);
  const width = useWindowSize();

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    var ignoreClick_On_Out_side_Element =
      document.getElementById('out_side_click');
    var ignoreClick_On_Main_Nav_Element =
      document.getElementById('sidebar-menu');
    document.addEventListener('click', function (event) {
      var isClickOutSideElement = ignoreClick_On_Out_side_Element.contains(
        event.target
      );
      var isClickMainNavElement = ignoreClick_On_Main_Nav_Element.contains(
        event.target
      );
      if (
        window.innerWidth <= 991 &&
        !isClickOutSideElement &&
        !isClickMainNavElement
      ) {
        //Do something click is outside specified element
        if (document.querySelector('.page-header'))
          document.querySelector('.page-header').className =
            'page-header close_icon';
        if (document.querySelector('.sidebar-wrapper'))
          document.querySelector('.sidebar-wrapper').className =
            'sidebar-wrapper close_icon ';
      }
    });
  }, [width]);

  const responsive_openCloseSidebar = (toggle) => {
    if (width <= 991) {
      document.querySelector('.page-header').className = 'page-header';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
    } else {
      if (toggle) {
        setSidebartoggle(!toggle);
        document.querySelector('.page-header').className =
          'page-header close_icon';
        document.querySelector('.sidebar-wrapper').className =
          'sidebar-wrapper close_icon ';
        document
          .querySelector('.mega-menu-container')
          .classList.remove('d-block');
      } else {
        setSidebartoggle(!toggle);
        document.querySelector('.page-header').className = 'page-header';
        document.querySelector('.sidebar-wrapper').className =
          'sidebar-wrapper ';
      }
    }
  };

  return (
    <Fragment>
      <div className="header-logo-wrapper" id="out_side_click">

        <div className="logo-wrapper">
          <img className="img-fluid for-light" src={logo_icon} alt="" />
          <img className="img-fluid for-dark" src={logo_icon} alt="" />
        </div>
        <div
          className="text-dart"
          onClick={() => responsive_openCloseSidebar(sidebartoggle)}
          style={
            window.innerWidth <= 991
              ? { display: 'block' }
              : { display: 'none' }
          }
        >
          <Menu
            className="status_toggle middle"

            id="sidebar-toggle"
          />
        </div>
      </div>
      <Col className="left-header  ">
        <Row>
          <Col lg='6' xs='12' >
            <CompaniesComponent />
          </Col>

        </Row>
      </Col>
    </Fragment>
  );
};

export default Leftbar;
