import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {Container,Button,Col} from "reactstrap"
import { FormattedMessage } from 'react-intl';
import AnimationComponent from '../../components/Animation';

const Error400 = () => {
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="error-wrapper">
          <Container>
            
             
            <center><AnimationComponent type="404" w={300} h={300} loop={true}/></center>
              <br/>
             
            <Col md="8 offset-md-2">
              <p className="sub-content"><FormattedMessage id="ERROR_404_DESCRIPTION" /></p>
            </Col>
            <Link to={`${process.env.PUBLIC_URL}/`}><Button color="dark" size='lg'><FormattedMessage id="BACK_TO_HOMEPAGE" /></Button></Link>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default Error400;