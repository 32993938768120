const Resource = require('../Resource');

module.exports = Resource.extend({
  list() {
    const instance = this.prepare();
    const URL = 'invoices/?start_date=2020-01-01';
    return instance.get(URL);
  },

  retrieve(id) {
    return this.prepare().get(`invoices/${id}`);
  },

  token() {
    return this.prepare().post(`invoices/token`);
  },

  providerInvoices() {
    return this.prepare().get(`invoices/provider/invoices?start_date=2020-01-01`);
    
  },
  
  providerInvoice(id) {
    return this.prepare().get(`invoices/provider/invoices/${id}`);
    
  }
});
