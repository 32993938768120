/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
//->import { Layout } from "../_metronic/layout";
import BasePage from './BasePage';
//import { Logout, AuthPage } from "./modules/Auth";
import { Logout, AuthPage } from './modules/Auth';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Sidebar from '../layout/sidebar';
import { ToastContainer } from 'react-toastify';

import ErrorsPage from "./modules/Error"
import ResetPassword from './modules/Auth/pages/ResetPassword';

function usePagesViews() {
  let location = useLocation();
  useEffect(() => {
    window.analytics.page();
  }, [location.pathname]);
}

export const MainRoutes = () => {
  const { isAuthorized } = useSelector(
    ({ user }) => {
      return {
        isAuthorized: user.role != null && user.privileges != null,
      }
    },
    shallowEqual
  );

  usePagesViews();


  return (
    <Routes>
      {!isAuthorized ? (
        <Route element={<AuthPage />} />

      ) : (<>
        {/*<RRedirect from="/auth" to="/" />*/}
        <Route path="/auth" element={<Navigate replace to={"/"} />} />

      </>)
      }

      <Route path="/error" element={<ErrorsPage />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/auth/reset-password" element={<ResetPassword />} />
      <Route path="/auth/login/*" element={<AuthPage />} />
      {
        !isAuthorized ? (<>
          {/*<RRedirect to="/auth/login" />*/}
          <Route path="/" element={<Navigate replace to={"/auth/login"} />} />
        </>) : (
          <Route path="/*" element={
            <Layout>
              <BasePage />
            </Layout>
          } />
        )
      }
    </Routes >
  );
};

const Layout = ({ children }) => {
  return (
    <>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper sidebar-icon">
          <Sidebar />

          <div className="page-body pl-4 pr-4" >{children}</div>
          <Footer />
        </div>
      </div>

      <ToastContainer />
    </>
  );
};