import React from "react";
import { useLang } from "./I18n";
import { IntlProvider } from "react-intl";

import enMessages from "../translations/en.json";
import esMessages from "../translations/es.json";

const allMessages = {

  en: enMessages,
  es: esMessages,

};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages} onError={(item) => {
      return item.descriptor.id;
    }}>
      {children}
    </IntlProvider>
  );
}
