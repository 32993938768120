import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';


import { put, takeLatest, } from 'redux-saga/effects';
import { getCompanyByIdAsync, getCompanyFundsAsync, getCompanyActivityAsync, getCompanyAccessAsync, getCompanyInvoicesAsync } from './companyCrud';
export const actionTypes = {
  GetCompanyById: 'GET__COMPANY__BY__ID',
  GetCompanyByIdCompleted: 'GET__COMPANY__BY__ID__COMPLETED',
  SetStatusCompanyById: 'SET__STATUS__COMPANY__BY__ID',

  GetCompanyFunds: 'GET__COMPANY__FUNDS',
  GetCompanyFundsCompleted: 'GET__COMPANY__FUNDS_COMPLETED',

  GetCompanyActivity: 'GET__COMPANY__ACTIVITY',
  GetCompanyActivityCompleted: 'GET__COMPANY__ACTIVIY_COMPLETED',

  GetCompanyAccess: 'GET__COMPANY__ACCESS',
  GetCompanyAccessCompleted: 'GET__COMPANY__ACCESS_COMPLETED',


  GetCompanyInvoices: 'GET__COMPANY__INVOICES',
  GetCompanyInvoicesCompleted: 'GET__COMPANY__INVOICES_COMPLETED',


  SetCompanyMasterSelected: 'SET__COMPANY_MASTER_SELECTED',
  GetCompanyMasterSelected: 'GET__COMPANY_MASTER_SELECTED'
};

// #####################################################
// #####################################################

const initialCompanyState = {
  statusFetch: 'initial',
  status: {},
  info: {
    contact: {
      first_name: '',
      last_name: '',
      phone_number: ''
    },
    name: '',
    payment_methods: [],
    total_cards: 0,
    active_cards: 0,
    created: 0,
    fee: 10.0,
    card_unit_price: 200.0,
    masters: []
  },

  balance: {
    statusFetch: 'initial',
    funds: {
      total: 0
    }
  },
  activity: {
    items: []
  },
  access: {
    data: []
  },
  invoices: {
    statusFetch: 'initial',
    data: []
  },

  master: null,
};

// #####################################################
// #####################################################

export const reducer = persistReducer(
  { storage, key: 'cl-company', whitelist: ['statusFetch', 'master', 'info', 'balance'] },
  (state = initialCompanyState, action) => {
    switch (action.type) {
      case actionTypes.SetStatusCompanyById: {

        const { status } = action.payload;

        return {
          ...state,
          statusFetch: status
        }
      }




      case actionTypes.GetCompanyByIdCompleted: {
        const { company } = action.payload;
        const companies = company.data;
        //Fix current Info?
        const selected = 0;

        const info = companies.data[selected];
        const master = state.master || info.masters[0].id;
        const funds = info.masters.find(i => i.id === master).funds;



        return {
          ...state,
          companies,
          info,
          master,
          balance: {
            statusFetch: 'success',
            funds: { ...funds }
          },
          statusFetch: 'success',
        };
      }


      case actionTypes.GetCompanyFundsCompleted: {
        const { funds } = action.payload;

        return {
          ...state,
          balance: {
            statusFetch: 'success',
            ...funds.data
          }
        };
      }

      case actionTypes.GetCompanyActivityCompleted: {
        const { activity } = action.payload;
        return {
          ...state,
          activity: activity.data,
          statusFetch: 'success',
        };
      }

      case actionTypes.GetCompanyAccessCompleted: {
        const { access } = action.payload;
        return {
          ...state,
          access: access.data,
          statusFetch: 'success',
        };
      }


      case actionTypes.GetCompanyInvoicesCompleted: {
        const { invoices } = action.payload;
        const result = invoices.data;
        return {
          ...state,
          invoices: {
            statusFetch: 'success',
            data: result.data,
          }
        };
      }

      case actionTypes.SetCompanyMasterSelected: {
        const { master } = action.payload;
        const funds = state.info.masters.find(i => i.id === master).funds;
        return {
          ...state,
          master,
          balance: {
            statusFetch: 'success',
            funds: { ...funds }
          },
        };
      }


      case actionTypes.GetCompanyMasterSelected: {
        return {
          master: state.master,

        }
      }


      default:
        return state;
    }
  }
);

// #####################################################
// #####################################################

export const actions = {
  // ------------------------------------------------------------
  requestCompanyById: () => {
    return { type: actionTypes.GetCompanyById };
  },
  requestCompanyByIdCompleted: function (company) {
    return { type: actionTypes.GetCompanyByIdCompleted, payload: { company } };
  },
  setStatusCompanyById: (status) => {
    return { type: actionTypes.SetStatusCompanyById, payload: { status } };
  },


  requestCompanyFunds: () => {
    return { type: actionTypes.GetCompanyFunds };
  },
  requestCompanyFundsCompleted: (funds) => {
    return { type: actionTypes.GetCompanyFundsCompleted, payload: { funds } };
  },

  requestCompanyActivity: () => {
    return { type: actionTypes.GetCompanyActivity };
  },
  requestCompanyActivityCompleted: (activity) => {
    return { type: actionTypes.GetCompanyActivityCompleted, payload: { activity } };
  },


  requestCompanyAccess: () => {
    return { type: actionTypes.GetCompanyAccess };
  },
  requestCompanyAccessCompleted: (access) => {
    return { type: actionTypes.GetCompanyAccessCompleted, payload: { access } };
  },


  requestCompanyInvoices: () => {
    return { type: actionTypes.GetCompanyInvoices };
  },
  requestCompanyInvoicesCompleted: (invoices) => {
    return { type: actionTypes.GetCompanyInvoicesCompleted, payload: { invoices } };
  },


  requestSetCompanyMasterSelected: (master) => {
    return { type: actionTypes.SetCompanyMasterSelected, payload: { master } };
  }
};

// #####################################################
// #####################################################

export function* saga() {
  yield takeLatest(
    actionTypes.GetCompanyById,
    function* getCompanyByIdSaga(action) {
      yield put(actions.setStatusCompanyById('initial'));
      try {
        const company = yield getCompanyByIdAsync();
        yield put(actions.requestCompanyByIdCompleted(company));
      } catch {
        yield put(actions.setStatusCompanyById('fail'));
      }
    }
  );


  yield takeLatest(
    actionTypes.GetCompanyFunds,
    function* getCompanyByIdSaga(action) {
      //->yield put(actions.setStatusCompany('initial'));
      try {
        const funds = yield getCompanyFundsAsync();
        yield put(actions.requestCompanyFundsCompleted(funds));
      } catch {
        //-> yield put(actions.setStatusCompanyById('fail'));
      }
    }
  );

  yield takeLatest(
    actionTypes.GetCompanyActivity,
    function* getCompanyByIdSaga(action) {
      //->yield put(actions.setStatusCompany('initial'));
      try {
        const activity = yield getCompanyActivityAsync();
        yield put(actions.requestCompanyActivityCompleted(activity));
      } catch {
        //-> yield put(actions.setStatusCompanyById('fail'));
      }
    }


  );


  yield takeLatest(
    actionTypes.GetCompanyAccess,
    function* getCompanyAccessSaga(action) {
      //->yield put(actions.setStatusCompany('initial'));
      try {
        const access = yield getCompanyAccessAsync();
        yield put(actions.requestCompanyAccessCompleted(access));
      } catch {
        //-> yield put(actions.setStatusCompanyById('fail'));
      }
    }

  );

  yield takeLatest(
    actionTypes.GetCompanyInvoices,
    function* getCompanyInvoicesSaga(action) {
      //->yield put(actions.setStatusCompany('initial'));
      try {
        const access = yield getCompanyInvoicesAsync();
        yield put(actions.requestCompanyInvoicesCompleted(access));
      } catch {
        //-> yield put(actions.setStatusCompanyById('fail'));
      }
    }

  );
}
