import React from 'react';

// a function to retry loading a chunk to avoid chunk load error for out of date code
// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport, name) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-' + name + '-refreshed') || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem('retry-' + name + '-refreshed', 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem('retry-' + name + '-refreshed', 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
};

//Company Page
const Dashboard = React.lazy(() => lazyRetry(() => import("../app/modules/Dashboard/pages/index"), 'Dashboard'));
const Company = React.lazy(() => lazyRetry(() => import("../app/modules/Company/pages/index"), 'Company'));
const Cards = React.lazy(() => lazyRetry(() => import("../app/modules/Cards/pages/index"), 'Cards'));
const Orders = React.lazy(() => lazyRetry(() => import("../app/modules/Orders/pages/index"), 'Orders'));
const Card = React.lazy(() => lazyRetry(() => import("../app/modules/Cards/pages/card"), 'CardsCard'));
const Groups = React.lazy(() => lazyRetry(() => import("../app/modules/Groups/pages/index"), 'Groups'));
const Group = React.lazy(() => lazyRetry(() => import("../app/modules/Groups/pages/group"), 'GroupsGroup'));
const Transactions = React.lazy(() => lazyRetry(() => import("../app/modules/Transactions/pages/index"), 'Transactions'));
const Transaction = React.lazy(() => lazyRetry(() => import("../app/modules/Transactions/pages/transaction"), 'TransactionsTransaction'));
const Transferences = React.lazy(() => lazyRetry(() => import("../app/modules/Transferences/pages/index"), 'Transferences'));
const Transference = React.lazy(() => lazyRetry(() => import("../app/modules/Transferences/pages/transference"), 'TransferencesTransference'));

const Invoices = React.lazy(() => lazyRetry(() => import("../app/modules/Invoices/pages/invoices"), 'Invoices'));

const Users = React.lazy(() => lazyRetry(() => import("../app/modules/Users/pages/index"), 'Users'));

const Services = React.lazy(() => lazyRetry(() => import("../app/modules/Services/pages/index"), 'Services'));



const routes = [

    {
        name: "DASHBOARD",
        rootMenuInfo: {
            title: "Dashboard",
        },
        privileges: [
            {
                name: "SEARCH",
                path: "/dashboard",
                component: Dashboard,
                menuInfo: {
                    visible: true,
                    title: "Dashboard",
                }
            }
        ]
    },

    {
        name: "COMPANY",
        rootMenuInfo: {
            title: "Empresa",
        },
        privileges: [
            {
                name: "SEARCH",
                path: "/company",
                component: Company,
                menuInfo: {
                    visible: true,
                    title: "Empresa",
                }
            },
        ]
    },


    {
        name: "CARDS",
        rootMenuInfo: {
            title: "Tarjetas",
        },
        privileges: [
            {
                name: "SEARCH",
                path: "/cards",
                component: Cards,
                menuInfo: {
                    visible: true,
                    title: "TarjetasInfo",
                }
            },
            {
                name: "VIEW",
                path: "/cards/:id",
                component: Card,
                menuInfo: {
                    visible: true,
                    title: "Tarjeta",
                }
            },
            {
                name: "NEW",
                path: "/cards/new",
                component: Card,
                menuInfo: {
                    visible: true,
                    title: "Tarjeta",
                }
            },
            {
                name: "NEW",
                path: "/cards/:id/two",
                component: Card,
                menuInfo: {
                    visible: true,
                    title: "Tarjeta",
                }
            },
        ]
    },

    {
        name: "USERS",
        rootMenuInfo: {
            title: "Equipo",
        },
        privileges: [
            {
                name: "SEARCH",
                path: "/users",
                component: Users,
                menuInfo: {
                    visible: true,
                    title: "UsersInfo",
                }
            }/*,
            {
                name: "VIEW",
                path: "/users/:id",
                component: User,
                menuInfo: {
                    visible: true,
                    title: "User",
                }
            },
            {
                name: "NEW",
                path: "/users/new",
                component: User,
                menuInfo: {
                    visible: true,
                    title: "Users",
                }
            },*/
        ]
    },


    {
        name: "ORDERS",
        rootMenuInfo: {
            title: "Ordenes",
        },
        privileges: [
            {
                name: "SEARCH",
                path: "/orders",
                component: Orders,
                menuInfo: {
                    visible: true,
                    title: "Orders",
                }
            },
        ]
    },



    {
        name: "GROUPS",
        rootMenuInfo: {
            title: "Groups",
        },
        privileges: [
            {
                name: "SEARCH",
                path: "/groups",
                component: Groups,
                menuInfo: {
                    visible: true,
                    title: "Groups",
                }
            }, {
                name: "VIEW",
                path: "/groups/:id",
                component: Group,
                menuInfo: {
                    visible: true,
                    title: "Groups",
                }
            },
        ]
    },




    {
        name: "SERVICES",
        rootMenuInfo: {
            title: "Services",
        },
        privileges: [
            {
                name: "SEARCH",
                path: "/services",
                component: Services,
                menuInfo: {
                    visible: true,
                    title: "Services",
                }
            }
        ]
    },


    {
        name: "TRANSACTIONS",
        rootMenuInfo: {
            title: "TRANSACTIONS",
        },
        privileges: [
            {
                name: "SEARCH",
                path: "/transactions",
                component: Transactions,
                menuInfo: {
                    visible: true,
                    title: "Transactions",
                }
            }, {
                name: "VIEW",
                path: "/transactions/:id",
                component: Transaction,
                menuInfo: {
                    visible: true,
                    title: "Transaction",
                }
            },
        ]
    },


    {
        name: "TRANSFERENCES",
        rootMenuInfo: {
            title: "TRANSFERENCES",
        },
        privileges: [
            {
                name: "SEARCH",
                path: "/transferences",
                component: Transferences,
                menuInfo: {
                    visible: true,
                    title: "TRANSFERENCES",
                }
            }, {
                name: "VIEW",
                path: "/transferences/:id",
                component: Transference,
                menuInfo: {
                    visible: true,
                    title: "Transferencia",
                }
            },
        ]
    },


    {
        name: "INVOICES",
        rootMenuInfo: {
            title: "Invoices",
        },
        privileges: [
            {
                name: "SEARCH",
                path: "/invoices",
                component: Invoices,
                menuInfo: {
                    visible: true,
                    title: "INVOICES",
                }
            }/*,{
                name: "VIEW",
                path: "/invoices/:id",
                component: Invoice,
                menuInfo: {
                    visible: true, 
                    title: "Invoice",
                }
            },*/
        ]
    },

]

export default routes;