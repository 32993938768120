const resources = require('./resources/index.js');

const DEFAULT_HOST = process.env.REACT_APP_API_ENDPOINT
const DEFAULT_PORT = '443';
const DEFAULT_BASE_PATH = '/v1/';
const DEFAULT_API_VERSION = null;

CL.USER_AGENT = {
  bindings_version: CL.PACKAGE_VERSION,
  lang: 'node',
  lang_version: process.env.version,
  platform: process.env.platform,
  publisher: 'cl',
  uname: null,
  typescript: false,
};

CL.resources = resources;

function CL(key, config = {}) {
  if (!(this instanceof CL)) {
    return new CL(key, config);
  }

  this._api = {
    auth: null,
    host: DEFAULT_HOST,
    port: DEFAULT_PORT,
    protocol: 'https',
    basePath: DEFAULT_BASE_PATH,
    version: DEFAULT_API_VERSION,

    agent: CL.USER_AGENT,
    dev: false,
  };

  this._prepResources();
  this.setApiKey(key);

  // Expose CLResource on the instance too
  this.CLResource = CL.CLResource;
}

CL.prototype = {
  setApiKey(key) {
    this.setApiField('auth', `Bearer ${key}`);
  },

  _prepResources() {
    for (const name in resources) {
      this[name] = new resources[name](this);
    }
  },
  setApiField(key, value) {
    this._api[key] = value;
  },
  getApiField(key) {
    return this._api[key];
  },
};

module.exports = CL;

module.exports.CL = CL;
module.exports.default = CL;
