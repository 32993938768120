/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import ResetPassword from './ResetPassword';


export function AuthPage() {
  console.log('authPage');
  return (
    <>
      <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
        <div
          className="d-flex flex-column flex-column-fluid flex-center mt-lg-0"
          style={{ backgroundColor: '#FFFFFF' }}
        >
          <Routes>
            {/*<ContentRoute path="/auth/login" exact={true} component={Login} />
            <ContentRoute
              path="/auth/reset-password"
              exact={true}
              component={ResetPassword}
  />*/}

            <Route path="/" element={<Login />} />

            <Route
              path="/auth/reset-password"
              exact={true}
              element={<ResetPassword />}
            />

            {/*<RRedirect from="/auth" exact={true} to="/auth/login" />
            <RRedirect to="/auth/login" />*/}
            <Route path="/auth" element={<Navigate replace to={"/auth/login"} />} />
            {/*<Route element={<Navigate replace to={"/auth/login"} />} />*/}
            <Route path="/logout" element={<Navigate replace to={"/auth/login"} />} />
            <Route path="logout" element={<Navigate replace to={"/auth/login"} />} />

          </Routes>
        </div>
      </div>
    </>
  );
}
