import { all } from "redux-saga/effects";
import { combineReducers } from "redux";


import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as company from "../app/modules/Company/_redux/companyRedux";
import * as user from "../app/modules/UserProfile/_redux/userRedux";
import * as users from "../app/modules/Users/_redux/usersRedux";
import * as cards from "../app/modules/Cards/_redux/cardsRedux";
import * as orders from "../app/modules/Orders/_redux/ordersRedux";
import * as dashboard from "../app/modules/Dashboard/_redux/dashboardRedux";
import * as groups from "../app/modules/Groups/_redux/groupsRedux";
import * as transactions from "../app/modules/Transactions/_redux/transactionsRedux";

import * as transferences from "../app/modules/Transferences/_redux/transferencesRedux";
import * as invoices from "../app/modules/Invoices/_redux/invoicesRedux";
import * as services from "../app/modules/Services/_redux/servicesRedux";

const appReducer = combineReducers({
  auth: auth.reducer,
  company: company.reducer,
  user: user.reducer,
  cards: cards.reducer,
  users: users.reducer,
  orders: orders.reducer,
  dashboard: dashboard.reducer,
  groups: groups.reducer,
  transactions: transactions.reducer,
  transferences: transferences.reducer,
  invoices: invoices.reducer,
  services: services.reducer

});


export const rootReducer = (state, action) => {
  if (action.type === auth.actionTypes.LogoutComplete) {
    const { auth, user } = state;
    state = { auth, user };


    state = {};
  }

  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([
    auth.saga(),
    company.saga(),
    user.saga(),
    cards.saga(),
    users.saga(),
    orders.saga(),
    dashboard.saga(),
    groups.saga(),
    transactions.saga(),
    transferences.saga(),
    invoices.saga(),
    services.saga(),

  ]);
}
