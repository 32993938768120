import { getTransferences, deposit, debit, getOrder } from '../../../provider/api/core';

export function getTransferencesAsync() {
  return getTransferences();
}

export function depositAsync(data) {
  return deposit(data);
}

export function debitAsync(data) {
  return debit(data);
}


export function getOrderAsync(data) {
  return getOrder(data);
}

