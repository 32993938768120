import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';

import axios from 'axios';
import setupAxios from './redux/setupAxios';
import store, { persistor } from './redux/store';
import App from './app/App';
import './index.scss'; // Standard version
import { I18nProvider } from './I18n/I18nProvider';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const { PUBLIC_URL } = process.env;

setupAxios(axios, store);
Sentry.init({
  dsn: 'https://e738c39af1624263a8eac73c32a3b1ad@o594505.ingest.sentry.io/5741947',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);


root.render(
  <I18nProvider>
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />
  </I18nProvider>
);
