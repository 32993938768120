import routes from "../RoleRoutes"

export const getRoutes = (privileges) => {

    let arrRoutes = []
    routes.forEach((globalRoute) => {
        privileges.forEach((smallRoute) => {
            if (globalRoute.name === smallRoute.name) {
                arrRoutes = [
                    ...arrRoutes,
                    ...globalRoute.privileges.filter((item) => smallRoute.privileges.indexOf(item.name) > -1),
                ]
            }
        });
    })

    return arrRoutes

}

export const getMenuItems = (privileges) => {
    let arrMenu = []
    routes.forEach((globalRoute) => {
        privileges.forEach((smallRoute) => {
            if (globalRoute.name === smallRoute.name) {
                
                arrMenu = [
                    ...arrMenu,
                    {
                        ...smallRoute,
                        ...globalRoute,
                        privileges: globalRoute.privileges.filter((item) => smallRoute.privileges.indexOf(item.name) > -1 && item.menuInfo.visible),
                        
                    }
                ]
            }
        });
    })
    arrMenu.sort(function (a, b) {
        return (a.order > b.order) ? 1 : -1;
    });

    return arrMenu

}


export const getPermissionsByActions = (privileges, section, action) => {

    const sectionPermission = privileges.find(item => item.name === section && item.privileges.includes(action))
    
    return Boolean(sectionPermission)

}

export const setStringDate = (strDate) => {
    let date = new Date(strDate)
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    return `${year}-${month}-${day}`
}

export const setStringDateDiaMonthYear = (strDate) => {
    let date = new Date(strDate)
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    return `${day}/${month}/${year}`
}


export const splitMountWithCommas = ( amount )=> {

    var decimals = 2;
    var x = Math.pow(10, Number(decimals) + 1);
    var fixedNumber = (Number(amount) + (1 / x)).toFixed(decimals)

    if(Number.isInteger(parseFloat(fixedNumber)) ){
        return "$" + parseFloat(fixedNumber).toLocaleString('en') + ".00"
    }else{
        return "$" + parseFloat(fixedNumber).toLocaleString('en')
    }
}

// Si el valor de días es negativo, resta los días a a fecha.

export function addOrRestDaysToDate(date, days){
    date.setDate(date.getDate() + days);
    return date;
}

// Regresa el primer día y el último día en un arreglo.

export const returnLastDayOfMonth = () => {

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    let firstDayString = `${firstDay.getFullYear()}-${ firstDay.getMonth() + 1 < 10 ? `0${firstDay.getMonth() + 1}` : firstDay.getMonth() + 1 }-${ firstDay.getDate()}` 
    let lastDayString = `${lastDay.getFullYear()}-${ lastDay.getMonth() + 1 < 10 ? `0${lastDay.getMonth() + 1}` : lastDay.getMonth() + 1 }-${ lastDay.getDate()}` 

    return [firstDayString, lastDayString]
}


