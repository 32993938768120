import { connect } from 'react-redux';

import { Fragment, useEffect, useState } from 'react';
import { ArrowLeft, Copy, X } from 'react-feather';

import {
  Button,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  Row,
  Table,
} from 'reactstrap';
import { formatAmount } from '../../../_helpers/format';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { FaCalculator } from 'react-icons/fa';

const ItemInput = ({ name, value, include_spaces = true }) => {
  const onCopy = (e) => {
    toast.dark(`Se ha copiado '${value}'`);
  };

  return (
    <Row className="mt-4">
      <Col xs={11}>
        <small>{name}</small>
        <CopyToClipboard text={include_spaces ? value : value.replace(/\s/gi, '')} onCopy={onCopy}>
          <Input
            readOnly={true}

            style={{ backgroundColor: 'transparent', cursor: 'pointer' }}
            className="form-control mt-1 p-4 cursor"
            value={value}
          />
        </CopyToClipboard>

      </Col>
      <Col xs={1} className="p-0 m-0 pt-4 ">
        <CopyToClipboard text={include_spaces ? value : value.replace(/\s/gi, '')} onCopy={onCopy}>
          <Button className="p-1 pt-3 pb-2 text-left" color="light" outline>
            <Copy size={15} color={'black'} />
          </Button>
        </CopyToClipboard>
      </Col>
    </Row>
  );
};

const noBorder = {
  border: '0px',

  boxShadow:
    'none' /* You may want to include this as bootstrap applies these styles too */,
};

const InstructionsComponent = (props) => {
  const { company } = props;


  const [show, setShow] = useState(false);

  //const [total, setTotal] = useState(0);
  const [fee, setFee] = useState({
    amount: 0,
    deposit_total: 0,
    perc: 0,
    text: 0,
    iva: 0,
    total: 0,
  });

  useEffect(() => {
    onChange('100000');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.fee]);

  const [amount, setAmount] = useState(0.0);

  const onChange = (keyword) => {
    let x = keyword.replace(/[^0-9.?]/gi, '').substr(0, 8);

    let splices = x.split('.');
    if (splices.length > 1) splices[1] = splices[1].substr(0, 2);

    x = splices.splice(0, 2).join('.');

    let amount = parseFloat(x);

    let perc = company.fee;

    //if(type == 'receive') {
    amount = amount / (1 - (perc / 100) * 1.16);
    // }

    let com = (amount * perc) / 100;
    let iva = com * 1.16 - com;

    fee.amount = amount;
    fee.deposit_total = formatAmount(amount);
    fee.perc = perc;
    fee.text = formatAmount(com);
    fee.iva = formatAmount(iva);
    fee.total = formatAmount(amount - com - iva);

    setFee(fee);

    setAmount('$' + fee.total);

    return (x.length > 0 ? '$' : '') + x;
  };

  const payment_methods = [
    {
      type: 'spei',
      account_number: '062 18000 133126597 6',
      beneficiary: 'CROSS LOOP SAPI DE CV',
      bank: '062',
      bank_name: 'AFIRME',
    },
  ];

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        size="md"
        style={{ maxWidth: '400px' }}
      >
        <ModalBody>


          <Container style={{ minHeight: '550px' }}>
            <Container className={"pt-4 pl-2 pr-2 " + (!show ? '' : 'hidden')}>
              <Button color="default" className="p-0 pb-4 " onClick={props.toggle}>
                <X />
              </Button>

              <h4>Abona a tu cuenta</h4>
              <span>Abona saldo a tu cuenta maestra</span>

              <br />
              <br />
              {payment_methods.map((item, index) => (
                <div key={index}>
                  <div className="d-flex align-items-top m-0" title="">
                    <div className="flex-fill ">
                      {/*<div className="text-sm mb-2">
                        <br />
                        <br />
                        <img
                          src={process.env.REACT_APP_IMAGE_URL + 'spei.png'}
                          height="14"
                          alt="SPEI"
                        />
                        <br />
                      </div>*/}

                      <ItemInput
                        value={item.account_number}
                        name="CLABE"
                        include_spaces={false}
                      />
                      <ItemInput value={item.beneficiary} name="CUENTA" />
                      <ItemInput value={item.bank_name} name="BANCO" />
                    </div>
                  </div>
                </div>
              ))}

              <Row className='mt-5 '>

                <Col className='text-right pr-0'>
                  <Button size='' color='light' outline onClick={() => { setShow(s => !s) }}>
                    <FaCalculator size={10} />&nbsp;&nbsp;&nbsp;&nbsp;Calcular comisión
                  </Button>
                </Col>
              </Row>
            </Container>

            <Container className={"pt-4 pl-2 pr-2 " + (show ? '' : 'hidden')}>
              <Button color="default" className="p-0 pb-4 " onClick={() => setShow(s => !s)}>
                <ArrowLeft />
              </Button>
              <h4>¿Cuánto deseas</h4>
              <h4>fondear?</h4>

              <br />
              <br />

              <Table borderless className="p-0 m-0">
                <tbody>
                  <tr className="b-b-light">
                    <td width="85" className="pt-5 pl-0 text-nowrap h5">
                      Monto
                    </td>
                    <td className="pt-4 pr-0">
                      <Input
                        type="amount"
                        style={noBorder}
                        className={'form-control pr-0 pt-3 text-right h3 '}
                        placeholder="$0.00"
                        autoFocus
                        defaultValue={amount}
                        onChange={(e) => {
                          e.target.value = onChange(e.target.value);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>

              <div>
                <br />
                <p className="mb-2 text-sm lh-140">
                  Para recibir esta cantidad en tu cuenta
                </p>
                <p className="mb-2 text-sm lh-140">
                  Es necesario depositar
                  <b className="float-right text-secondary" id="deposit_total">
                    ${fee.deposit_total}
                  </b>
                </p>
                <p className="mb-2 text-sm lh-140">
                  - Comisión (
                  <b className="text-sm" id="perc">
                    {company.fee}%
                  </b>
                  ){' '}
                  <span className="float-right text-secondary" id="text">
                    -${fee.text}
                  </span>
                </p>
                <p className="mb-2 text-sm lh-140">
                  - IVA de comisión (16%)
                  <span className="float-right text-secondary" id="iva">
                    -${fee.iva}
                  </span>
                </p>
                <p className="mb-2 text-sm lh-140">
                  Recibirás en tu cuenta
                  <span className="float-right text-secondary" id="total">
                    ${fee.total}
                  </span>
                </p>

                <br />
                <br />
                {/*<PaymentMethodsComponent title={`DEPOSITO ${company.name}`} />
                <br />*/}

                <small className="mb-2 text-secondary lh-140">
                  * La comisión de servicio se calcula sobre el monto{' '}
                  <b>depositado</b>
                </small>
                <br />
                <small className="mb-4 text-secondary lh-140">
                  * No se aplicarán comisiones adicionales
                </small>
                <br />
                <br />
              </div>
            </Container>
          </Container>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default connect(({ company }) => {
  return {
    company: company.info,
  };
}, {})(InstructionsComponent);
