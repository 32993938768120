const axios = require('axios');

const hasOwn = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
const utils = {
  protoExtend(sub) {
    const Super = this;
    const Constructor = hasOwn(sub, 'constructor')
      ? sub.constructor
      : function (...args) {
        Super.apply(this, args);
      };
    Object.assign(Constructor, Super);
    Constructor.prototype = Object.create(Super.prototype);
    Object.assign(Constructor.prototype, sub);

    return Constructor;
  },
};

Resource.extend = utils.protoExtend;

function Resource(core) {
  this._core = core;
}

Resource.prototype = {
  path: '',

  prepare() {
    const authToken = this._core.getApiField('auth');
    const baseUrl = this._core.getApiField('protocol') +
      '://' +
      this._core.getApiField('host') +
      this._core.getApiField('basePath');
    const instance = axios.create({
      baseURL: baseUrl
      ,
      timeout: 20000,
    });

    if (authToken)
      instance.defaults.headers.common['Authorization'] = authToken;

    return instance;
  },


  jsonToQueryString(json) {
    if (!json) json = {}
    return '?' +
      Object.keys(json).map(function (key) {
        return encodeURIComponent(key) + '=' +
          encodeURIComponent(json[key]);
      }).join('&');
  },


};

module.exports = Resource;
