import {
  Home,
  BarChart2,
  CreditCard,
  //  Inbox,
  Users,
  User,
  Lock,
  Shield,
  Activity,

  //  Navigation,
  FileText,
  Settings,
  Globe,

  //Layers,
} from 'react-feather';

import satIco from '../../assets/images/other-images/sat.ico.png';


export const MENUITEMS = [
  {
    Items: [
      {
        title: 'Inicio',
        icon: Home,
        type: 'link',
        active: true,
        path: `${process.env.PUBLIC_URL}/dashboard`,
      },
      {
        title: 'Tarjetas',
        icon: CreditCard,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/cards`,
      },
      {
        title: 'Equipo',
        icon: Users,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/users`,
      },



      {
        title: 'Transacciones',
        icon: BarChart2,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/transactions`,
      },


      {
        title: 'Pago de servicios',
        icon: Globe,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/services`,
      },

      /*{
        title: 'Transferencias',
        icon: Navigation,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/transferences`,
      },*/
      /*{
        title: 'Solicitudes',
        icon: Inbox,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/orders`,
      },*/
      /*{
        title: 'Grupos',
        icon: Layers,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/groups`,
      },*/


      {

        title: 'Facturas',
        image: <img src={satIco} height="18" alt="whatsapp" />,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/invoices`,

      },

      {

        title: 'Configuración',
        icon: Settings,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/company`,
        only_small: true

      },

      {
        title: 'Perfil',
        icon: User,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/company`,
        hidden: true,
      },
      {
        title: 'Seguridad > Cambiar contraseña',
        icon: Lock,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/company/security`,
        hidden: true,
      },
      {
        title: 'Eventos > Accesos',
        icon: Shield,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/company/access`,
        hidden: true,
      },
      {
        title: 'Actividad',
        icon: Activity,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/company/activity`,
        hidden: true,
      },
      {
        title: 'Depósitos',
        icon: FileText,
        type: 'link',
        active: false,
        path: `${process.env.PUBLIC_URL}/company/invoices`,
        hidden: true,
      },


    ],
  },
];
