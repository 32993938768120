const Resource = require('../Resource');

module.exports = Resource.extend({
  list() {
    const master = sessionStorage.getItem('master');
    return this.prepare().get(`cards/${master}`);
  },
  retrieve(id) {
    const master = sessionStorage.getItem('master');
    return this.prepare().get(`cards/${master}/${id}`);
  },
  nip(id, body) {
    const master = sessionStorage.getItem('master');
    return this.prepare().post(`cards/${master}/${id}/nip`, body);
  },
  update(id, body) {
    const master = sessionStorage.getItem('master');
    return this.prepare().post(`cards/${master}/${id}`, body);
  },
  create(body) {
    const master = sessionStorage.getItem('master');
    return this.prepare().post(`cards/${master}`, body);
  },
  requestNewCards(body) {
    const master = sessionStorage.getItem('master');
    return this.prepare().post(`cards/${master}/request`, body);
  },
});
