import { getOrders, updateOrders } from '../../../provider/api/core'

export function getOrdersAsync() {
    return getOrders();
}


export function updateOrderAsync(data) {
    return updateOrders(data);
}
 