import * as cognitoAuth from '../../../provider/cognito/auth';
export const LOGIN_URL = 'credentials/login';
export const REFRESH_TOKEN_URL = 'credentials/refresh';
export const LOGOUT_URL = 'credentials/logout';
export const REQUEST_PASSWORD_URL = 'user/rstPasswd';
export const RESET_PASSWORD_URL = 'user/rstPasswd';
export const ME_URL = 'user';

export const REGISTER_URL = 'company';
export const VALIDATE_EMAIL_TOKEN = 'user/validateToken';
export const RESEND_EMAIL_TOKEN = 'user/resendToken';

export function login(email, password) {
  return cognitoAuth.signInWithEmail(email, password);
}

export function register(email, phone_number, password) {
  return cognitoAuth.signUp(email, password);
}

export function requestChangePassword({oldPassword, newPassword}) {
  return cognitoAuth.changePassword({oldPassword, newPassword});
}

export function forgotPassword({email}) {
  return cognitoAuth.forgotPassword({email})
}

export function confirmPassword({email, code, password}) {
  return cognitoAuth.confirmPassword({email, code, password})
}
export function requestLogout() {
  return cognitoAuth.signOut();
}









export function refreshTokenAsync(refreshToken) {
  // return axios.post(REFRESH_TOKEN_URL, refreshToken );
}

export function requestPassword(email, captchaResponse) {
  // return axios.post(REQUEST_PASSWORD_URL,{username: email}, {headers: {'captcha-response': captchaResponse}});
}

export function validateEmailToken(token, type, userUUID) {
  // return axios.post(VALIDATE_EMAIL_TOKEN, { token, type, userUUID })
}

export function resendEmailTokenAsync(token, type, userUUID) {
  // return axios.post(RESEND_EMAIL_TOKEN, { token, type, userUUID })
}

export function activateAccount(newPasswd, token) {
  // return axios.put(RESET_PASSWORD_URL, { newPasswd, token, sendEmail: false });
}
