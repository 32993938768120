import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';

import {
  requestLogout,
  validateEmailToken,
  resendEmailTokenAsync,
  refreshTokenAsync,
  requestChangePassword,
} from './authCrud';

//->import { ParseJwt } from '_metronic/_helpers';

export const actionTypes = {
  Login: '[Login] Action',

  Logout: '[Logout] Action',
  LogoutComplete: '[Logout] Action Completed',

  Register: '[Register] Action',

  ValidateEmailToken: 'VALIDATE__EMAIL__TOKEN',
  ValidateEmailTokenComplete: 'VALIDATE__EMAIL__TOKEN__COMPLETE',
  ValidateEmailTokenError: 'VALIDATE__EMAIL__TOKEN__ERROR',

  ResendEmailToken: 'RESEND_EMAIL_TOKEN',
  ResendEmailTokenComplete: 'RESEND_EMAIL_TOKEN_COMPLETE',
  ResendEmailTokenError: 'RESEND_EMAIL_TOKEN_ERROR',

  RefreshToken: 'REFRESH__TOKEN',
  RefreshTokenComplete: 'REFRESH__TOKEN__COMPLETE',
  RefreshTokenError: 'REFRESH__TOKEN__ERROR',

  ChangePassword: 'CHANGE__PASSWORD',
  ChangePasswordComplete: 'CHANGE__PASSWORD__COMPLETE',
  ChangePasswordError: 'CHANGE__PASSWORD__ERROR',

  DeleteAuthInitialData: 'DELETE__AUTH_INITIAL_DATA',
};

const initialAuthState = {
  authToken: {},
  emailTokenStatus: 'initial',
  resendTokenStatus: 'initial',
  resetPasswdToken: '',
  isUpdating: false,
  emailTokenErrorCode: '',
};

export const reducer = persistReducer(
  { storage, key: 'cl-auth', whitelist: ['authToken'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, refreshToken } = action.payload;
        const exp = action.payload.data.exp;

        return {
          ...state,
          authToken: {
            exp,
            authToken,
            refreshToken,

            //->exp:  ParseJwt(authToken.bearerToken).exp,
          },
        };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;
        return { authToken };
      }

      case actionTypes.LogoutComplete: {
        return initialAuthState;
      }

      // -------------------------------------
      // -------------------------------------
      // -------------------------------------

      case actionTypes.ValidateEmailTokenComplete: {
        const { resetPasswdToken } = action.payload;
        return {
          ...state,
          emailTokenStatus: 'success',
          resetPasswdToken,
        };
      }

      case actionTypes.ValidateEmailTokenError: {
        const { status } = action.payload;

        return {
          ...state,
          emailTokenStatus: status,
        };
      }
      // -------------------------------------
      // -------------------------------------
      // -------------------------------------

      case actionTypes.ResendEmailTokenComplete: {
        return {
          ...state,
          resendTokenStatus: 'success',
        };
      }

      case actionTypes.ResendEmailTokenError: {
        return {
          ...state,
          resendTokenStatus: 'error',
        };
      }

      case actionTypes.RefreshToken: {
        return {
          ...state,
          isUpdating: true,
        };
      }

      case actionTypes.RefreshTokenComplete: {
        const { authToken } = action.payload;
        return {
          ...state,
          authToken: {
            ...state.authToken,
            ...authToken,
            //-> exp: ParseJwt(authToken.bearerToken).exp,
          },
          isUpdating: false,
        };
      }
      case actionTypes.DeleteAuthInitialData: {
        return initialAuthState;
      }
      default:
        return state;
    }
  }
);

export const actions = {
  login: (response) => {
    if (response.idToken) {
      response.headers = {
        authToken: response.idToken.jwtToken,
        refreshToken: response.refreshToken.token,
      };
      response.data = response.idToken.payload;
    }

    const { headers, data } = response;

    return {
      type: actionTypes.Login,
      payload: {
        authToken: headers.authToken,
        refreshToken: headers.refreshToken,
        data,
      },
    };
  },

  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => {
    return { type: actionTypes.Logout };
  },
  logoutComplete: () => ({ type: actionTypes.LogoutComplete }),
  validateEmailToken: (emailToken, type, uuid) => ({
    type: actionTypes.ValidateEmailToken,
    payload: { emailToken, type, uuid },
  }),
  validateEmailTokenComplete: (resetPasswdToken) => ({
    type: actionTypes.ValidateEmailTokenComplete,
    payload: { resetPasswdToken },
  }),
  validateEmailTokenError: (status) => ({
    type: actionTypes.ValidateEmailTokenError,
    payload: { status },
  }),
  resendEmailToken: (emailToken, type, uuid) => ({
    type: actionTypes.ResendEmailToken,
    payload: { emailToken, type, uuid },
  }),
  resendEmailTokenComplete: () => ({
    type: actionTypes.ResendEmailTokenComplete,
  }),
  resendEmailTokenError: () => ({ type: actionTypes.ResendEmailTokenError }),
  refreshTokenAction: (refreshToken) => ({
    type: actionTypes.RefreshToken,
    payload: { refreshToken },
  }),
  refreshTokenActionComplete: (authToken) => ({
    type: actionTypes.RefreshTokenComplete,
    payload: { authToken },
  }),

  changePasswordActionComplete: (status) => ({
    type: actionTypes.ChangePasswordComplete,
    payload: { status },
  }),
  deleteAuthInitialData: () => ({ type: actionTypes.DeleteAuthInitialData }),
};

export function* saga() {
  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(
    actionTypes.ValidateEmailToken,
    function* validateEmailTokenSaga({ payload }) {
      try {
        const { data } = yield validateEmailToken(
          payload.emailToken,
          payload.type,
          payload.uuid
        );
        yield put(actions.validateEmailTokenComplete(data.resetPasswdToken));
      } catch (e) {
        yield put(actions.validateEmailTokenError('error'));
      }
    }
  );

  yield takeLatest(
    actionTypes.ResendEmailToken,
    function* resendEmailTokenSaga({ payload }) {
      try {
        const { data } = yield resendEmailTokenAsync(
          payload.emailToken,
          payload.type,
          payload.uuid
        );
        yield put(actions.resendEmailTokenComplete(data));
      } catch {
        yield put(actions.resendEmailTokenError());
      }
    }
  );

  yield takeLatest(
    actionTypes.RefreshToken,
    function* refreshTokenSaga({ payload }) {
      try {
        const { data } = yield refreshTokenAsync(payload);
        yield put(actions.refreshTokenActionComplete(data));
      } catch (e) {
        console.log('no se pudo refrescar el token');
        // console.log( e.response )
        // yield put(actions.refreshTokenError());
        //TODO; CONSULTAR QUE HACER EN ESTE CASO
      }
    }
  );

  yield takeLatest(actionTypes.Logout, function* logoutSaga() {
    try {
      console.log('..logout');
      const { data } = yield requestLogout();
      yield put(actions.logoutComplete(data));
    } catch (ex) {
      console.log('error in logout ');
      console.log(ex);
      yield put(actions.logoutComplete());
    }
  });

  yield takeLatest(actionTypes.ChangePassword, function* logoutSaga(info) {
    try {
      const { data } = yield requestChangePassword(info);
      yield put(actions.changePasswordActionComplete(data));
    } catch (ex) {
      console.log('error in  logout ');
      console.log(ex);
      yield put(actions.changePasswordActionComplete(ex));
    }
  });
}
