import React, { useState } from 'react';

import * as company from '../../Company/_redux/companyRedux';

import { connect } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';

import InstructionsModal from './instructions';
import CountUp from 'react-countup';



const FundsComponent = (props) => {
  const { fetchStatus, full, funds } = props;


  const [isOpen, setIsOpen] = useState(false);


  const modalToggle = () => {
    setIsOpen(!isOpen);
  };



  return (
    <>
      <Button
        id="accountFunds"
        className={'tour-step-funds p-0 m-0 text-left   ' + (full ? 'btn-block' : '')}
        color="default"

        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {fetchStatus !== 'initial' ? (
          <>
            <small className='p-0 m-0 text-secondary'>Disponible</small>
            <h6 className='text-info p-0 m-0' style={{ minWidth: '100px' }}><small>$</small>

              <CountUp
                end={funds.total}
                decimals={2}
                decimal="."
                separator=","
                duration={1}
              />
            </h6>

          </>
        ) : (
          <></>
        )}
      </Button>

      <UncontrolledTooltip placement="left" target="accountFunds">
        Agregar fondos
      </UncontrolledTooltip>

      <InstructionsModal isOpen={isOpen} toggle={modalToggle} />

    </>
  );
};

export default connect(
  ({ company }) => {

    return {
      funds: company.balance.funds,
      info: company.info,
      fetchStatus: company.fetchStatus,
    };
  },
  { ...company.actions }
)(FundsComponent);
