import lottie from 'lottie-web';
import successAnimation from '../../assets/animations/1708-success.json';
import failAnimation from '../../assets/animations/fail.json';

import loading1Animation from '../../assets/animations/loading-1.json';
import loading2Animation from '../../assets/animations/loading-2.json';
import loading3Animation from '../../assets/animations/loading-3.json';
import loading4Animation from '../../assets/animations/loading-4.json';
import _404Animation from '../../assets/animations/404.json';

import { useEffect, useMemo } from 'react';


const AnimationComponent = (props) => {
  const { type, w, h, loop } = props;


  const ANIMATIONS = useMemo(() => {
    return {
      'success': successAnimation,
      'fail': failAnimation,
      'loading-1': loading1Animation,
      'loading-2': loading2Animation,
      'loading-3': loading3Animation,
      'loading-4': loading4Animation,
      '404': _404Animation
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      lottie.loadAnimation({
        container: document.querySelector('#react-logo'),
        animationData: ANIMATIONS[type],
        loop: loop ? true : false,
      });
    }, 100);
  }, [type, ANIMATIONS, loop]);

  return (

    <div id="react-logo" style={{ width: w || 200, height: h || 200 }} />

  );
};

export default AnimationComponent;