import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import * as user from '../../../../app/modules/UserProfile/_redux/userRedux';

import React, { useState } from 'react';

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
} from 'reactstrap';
import { toast } from 'react-toastify';
import {
  LoggingInEvent,
  LoggedInEvent,
  LoggedInFailedEvent,
} from '../../../provider/segment/events/auth';
import { login } from '../_redux/authCrud';
import * as Sentry from '@sentry/react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Login = (props) => {
  const cover = process.env.REACT_APP_IMAGE_URL + 'cover_full.jpg';
  const logo = process.env.REACT_APP_IMAGE_URL + 'logo_small.png';

  const { intl } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const tour = localStorage.getItem('tour');
    localStorage.clear();

    if (tour && tour !== 'null') localStorage.setItem('tour', tour);
  }, []);

  const loginAuth = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    LoggingInEvent({ email });

    login(email, password)
      .then((r) => {

        const payload = r.idToken.payload;

        LoggedInEvent(payload);

        Sentry.setUser({ email: payload.email });

        props.setInitialData(payload);
        props.login(r);

        setLoggedIn(true);

        navigate('/');
      })
      .catch((error) => {
        LoggedInFailedEvent({ email, error });

        setLoading(false);

        if (error.code === 'PasswordResetRequiredException') {
          setTimeout(() => {
            const e = intl.formatMessage({
              id: 'AUTH.PasswordResetRequiredException',
            });
            toast.error(e);
          }, 300);

          setTimeout(() => {
            navigate('/auth/reset-password');
          }, 5000);
        } else {
          let msg = intl.formatMessage({
            id: error.message,
          });
          if (msg === error.message) msg = '';

          setTimeout(() => {
            const e =
              intl.formatMessage({
                id: 'AUTH.VALIDATION.FAIL',
              }) + msg;
            toast(e);
          }, 200);
        }
      });
  };

  if (loggedIn) {
    //return <Route element={<Navigate to="/" />} />
    return <></>;
  }

  return (
    <Container fluid={true}>
      <Row>
        <Col
          xl="8"
          className="b-center bg-size"
          style={{
            backgroundImage: `url(${cover})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'block',
          }}
        >
          <img
            className="bg-img-cover bg-center"
            style={{ display: 'none' }}
            src={cover}
            alt="looginpage"
          />
        </Col>
        <Col xl="4" className="p-0">
          <div
            className="login-card"
            style={{ minHeight: '900px', maxHeight: '900px' }}
          >
            <div>
              <div className="login-main feature-products ">
                <br />
                <br />
                <br />

                <center>
                  <img className="img-fluid" src={logo} alt="" width="250" />
                </center>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <h3 className="mb-0  text-dark">Iniciar sesión</h3>

                <Label className="col-form-label text-secondary">
                  {'Inicia con tu correo y contraseña'}
                </Label>

                <br />
                <br />

                <Form className="mb-6 theme-form" id="signinForm">
                  <FormGroup>
                    <Label className="col-form-label text-dark">
                      Correo electrónico
                    </Label>
                    <Input
                      className="form-control form-control-md"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      defaultValue={email}
                      autoComplete="on"
                      tabIndex="1"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label  text-dark">
                      Contraseña
                    </Label>
                    <Input
                      className="form-control  form-control-md"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      defaultValue={''}
                      autoComplete="on"
                      placeholder="* * * * * * * * * * * *"
                      tabIndex="2"
                    />
                  </FormGroup>

                  <br />
                  <Button
                    block
                    className="btn-pill btn-air-primary p-3 m-2 mb-5"
                    type="submit"
                    color="dark"
                    onClick={loginAuth}
                    disabled={loading}
                    tabIndex="3"
                  >
                    {loading ? (
                      <>
                        <Spinner style={{ width: '15px', height: '15px' }} />{' '}
                        {'Iniciando sesión'}
                      </>
                    ) : (
                      'Iniciar sesión'
                    )}
                  </Button>
                  <br />
                  <hr />
                  <div className="text-right">
                    <Link className="text-primary" to="/auth/reset-password">
                      Olvidé mi contraseña
                    </Link>
                  </div>
                  <br />
                  <br />

                  <div className="text-center">
                    <small>
                      {process.env.REACT_APP_TERMS}. Todos los derechos
                      reservados {new Date().getFullYear()}.{' '}
                      {process.env.REACT_APP_VERSION}
                    </small>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default injectIntl(
  connect(null, { ...auth.actions, ...user.actions })(Login)
);
