import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Form, Media, Row } from 'reactstrap';
import { MENUITEMS } from '../sidebar/menu';
import LeftHeader from './leftbar';
import RightHeader from './rightbar';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { User, X } from 'react-feather';
import sad from '../../assets/images/other-images/search-not-found.png';

const Header = (props) => {
  const { cards } = props;

  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [searchValue, setsearchValue] = useState('');
  // eslint-disable-next-line
  const [searchResult, setSearchResult] = useState(false);
  // eslint-disable-next-line
  const [searchResultEmpty, setSearchResultEmpty] = useState(false);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setsearchValue('');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();

    const items = [];
    setsearchValue(keyword);

    cards.map((card) => {
      if (items.length > 20) return false;
      const n = (card.account && card.account.username.includes(keyword));

      const exists = card.name.toLowerCase().includes(keyword) ||
        card.last4.toLowerCase().includes(keyword) ||
        n;

      if (exists
      ) {
        items.push({
          icon: User,
          path: '/cards/' + card.id,
          title:
            card.name.toUpperCase() +
            ' **' +
            card.last4

        });
      } else return false;

      checkSearchResultEmpty(items);
      setsearchValue(items);
      return card;
    });

    mainmenu.map((menuItems) => {
      if (items.length > 20) return false;
      menuItems.Items.filter((mItems) => {
        if (mItems.title.toLowerCase().includes(keyword)) {
          items.push(mItems);
        }
        checkSearchResultEmpty(items);
        setsearchValue(items);
        return mItems;
      });
      return menuItems;
    });
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      setSearchResultEmpty(true);
      document.querySelector('.empty-menu').classList.add('is-open');
    } else {
      setSearchResultEmpty(false);
      document.querySelector('.empty-menu').classList.remove('is-open');
    }
  };

  const addFix = () => {
    setSearchResult(true);
    document.querySelector('.Typeahead-menu').classList.add('is-open');
  };

  const removeFix = () => {
    setSearchResult(false);
    setsearchValue('');
    document.querySelector('.Typeahead-menu').classList.remove('is-open');
  };

  return (
    <Fragment>
      <div className="page-header close_icon" style={{ opacity: 0.97 }}>
        <Row className="m-0 header-wrapper" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Form
            className="form-inline search-full"
            action="#"
            method="get"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="form-group w-100">
              <div className="Typeahead Typeahead--twitterUsers">
                <div className="u-posRelative">
                  <input
                    className="Typeahead-input form-control-plaintext w-100"
                    id="demo-input"
                    autoComplete="off"
                    type="text"
                    placeholder="Buscar usuario, tarjeta o grupo ..."
                    defaultValue={searchValue}
                    onChange={(e) => handleSearchKeyword(e.target.value.toLowerCase())}
                  />
                  <div
                    className="spinner-border Typeahead-spinner"
                    role="status"
                  >
                    <span className="sr-only">Loading ... </span>
                  </div>
                  <X
                    className="close-search"
                    onClick={() =>
                      document
                        .querySelector('.search-full')
                        .classList.remove('open')
                    }
                  />
                </div>
                <div
                  className="Typeahead-menu custom-scrollbar"
                  id="search-outer"
                >
                  {searchValue
                    ? searchValue.map((data, index) => {
                      return (
                        <Link
                          to={data.path}
                          className="realname text-dark"
                          onClick={removeFix}
                          key={index}
                        >
                          <div className="ProfileCard u-cf " key={index}>
                            <div className="ProfileCard-avatar">
                              {data.icon ? <data.icon /> : data.image}
                            </div>
                            <div className="ProfileCard-details">
                              <div className="ProfileCard-realName">
                                {data.title}
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })
                    : ''}
                </div>
                <div className="Typeahead-menu empty-menu">
                  <div className="tt-dataset tt-dataset-0">
                    <div className="p-4 EmptyMessage">
                      <Media body className="img-100" src={sad} alt="" />
                      <br />
                      <br />

                      {`No se han encontrado resultados para tu búsqueda.`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>

          <LeftHeader />
          <RightHeader />
        </Row>
      </div>
    </Fragment>
  );
};

export default connect(({ cards }) => {
  return {
    cards: cards.list.data,
  };
}, {})(Header);
