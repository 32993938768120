import React, { Fragment, useState } from 'react';
import { User, Search, Power, Moon, Sun } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import FundsComponent from '../../app/modules/Dashboard/pages/fundsComponent'; //'../../app/modules/Dashbboard/pages/inf';


const Rightbar = (props) => {
  const navigate = useNavigate();
  const [searchresponsive, setSearchresponsive] = useState(false);

  const s = localStorage.getItem('layout_mode');
  const [mode, setMode] = useState(s || 'light');

  const ModeToggle = (m) => {
    if (m === 'light') {
      setMode('dark')
      document.body.className = "dark-sidebar dark-only"
      localStorage.setItem('layout_mode', 'dark-only');
    } else {
      setMode('light')
      document.body.className = "dark-sidebar light"
      localStorage.setItem('layout_mode', 'light');
    }
  }



  //full screen function
  /*function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }*/

  const SearchResponsive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive);
      document.querySelector('.search-full').classList.add('open');
      //***document.querySelector(".more_lang").classList.remove("active");
    } else {
      setSearchresponsive(!searchresponsive);
      document.querySelector('.search-full').classList.remove('open');
    }
  };
  return (
    <Fragment>
      <div className="nav-right pull-right right-header p-0"  >


        <ul className="nav-menus">
          <li className='pt-0 pb-0  m-0  b-l-light pull-right' >

            <FundsComponent />
          </li>

          <li className='d-none d-md-block'>
            <a
              id="searchTarget"
              href="#action"
              onClick={() => SearchResponsive(!searchresponsive)}
              className="text-dark header-search"
            >
              <Search />
            </a>
          </li>
          <UncontrolledTooltip placement="left" target="searchTarget">
            Buscar
          </UncontrolledTooltip>

          <li className='d-none d-sm-block'>
            {/*<Link id="accountTarget" to={'/company'} className={`text-dark tour-step-account`} href="#action">




              
  </Link>
  
  
        <UncontrolledTooltip placement="left" target="accountTarget">
          Perfil
        </UncontrolledTooltip>
  */}

            <ButtonGroup>

              <UncontrolledDropdown>
                <DropdownToggle className='p-2 btn-pill btn-info' color='light'>
                  <User />
                </DropdownToggle>
                <DropdownMenu >

                  <DropdownItem onClick={() => {
                    navigate('/company');

                  }} className='p-3 text-dark'><User size={14} color='#000' />&nbsp;&nbsp;&nbsp;Perfil</DropdownItem>
                  <DropdownItem divider={true} className='p-0 m-0' />


                  <DropdownItem onClick={() => ModeToggle(mode)} className='p-3 text-dark'>
                    {mode === 'light' ? (
                      <>
                        <Moon size="14" color='#000' />
                        &nbsp;&nbsp;&nbsp; Modo obscuro
                      </>
                    ) : (
                      <>
                        <Sun size="14" color='#000' />
                        &nbsp;&nbsp;&nbsp; Modo claro
                      </>
                    )}
                  </DropdownItem>
                  <DropdownItem divider={true} className='p-0 m-0' />

                  <DropdownItem onClick={() => {
                    navigate('/logout');

                  }} className='p-3 text-dark'><Power size={14} color='#000' />&nbsp;&nbsp;&nbsp;Cerrar sesión</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>

          </li>

        </ul>
      </div>
    </Fragment >
  );
};

export default Rightbar;
