export const formatAmount = (amount) => {
  if (isNaN(amount)) amount = 0;

  return parseFloat(amount)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    .replace('$', '');
};

export const formatPhone = (phone_number) => {
  if (!phone_number) return '';
  if (isNaN(phone_number)) return phone_number;

  return `(${phone_number.substr(0, 2)}) ${phone_number.substr(
    2,
    4
  )} ${phone_number.substr(6)}`;
};



var MONTH_NAMES = [
  '',
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

var WEEKDAY_NAMES = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miercoles',
  'Jueves',
  'Viernes',
  'Sábado',
];

export const formatDate = (dt) => {
  let date = dt;
  if (typeof (dt) === 'object') {
    date = dt.toISOString().substr(0, 10) + " " + dt.toLocaleTimeString();

  }

  return (
    MONTH_NAMES[parseInt(date.substr(5, 2))] +
    ' ' +
    date.substr(8, 2) +
    ', ' +
    (date.length === 10 ? date.substr(0, 4) : date.replace("T", "").substr(10, 5))
  );
};


export const formatDayOfWeek = (dt) => {
  let date = new Date();
  date.setMonth(parseInt(dt.substr(5, 2)));
  date.setDate(parseInt(dt.substr(8, 2)));
  date.setFullYear(parseInt(dt.substr(0, 4)));


  return (
    WEEKDAY_NAMES[date.getDay()]
  );
};

export const formatShortDate = (date) => {
  return (
    MONTH_NAMES[parseInt(date.substr(5, 2))].substr(0, 3) +
    ' ' +
    date.substr(8, 2) +
    ', ' +
    (date.length === 10 ? date.substr(0, 4) : date.substr(11, 5))
  );
};

export const formatMonth = (date) => {
  return date.length > 0
    ? MONTH_NAMES[parseInt(date.substr(5, 2))] + ' ' + date.substr(0, 4)
    : '';
};

export { MONTH_NAMES, WEEKDAY_NAMES };
