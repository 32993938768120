import { put, takeLatest } from 'redux-saga/effects';
import { getTransactionsAsync } from './transactionsCrud';
export const actionTypes = {
  GetTransactions: 'GET__TRANSACTIONS',
  GetTransactionsCompleted: 'GET__TRANSACTIONS_COMPLETED',
  SetStatusTransactions: 'SET__STATUS__TRANSACTIONS',

  SetTransactionsFilterSortBy: 'SET__TRANSACTIONS__FILTER_SORTBY',
  SetTransactionsFilterSearchBy: 'SET__TRANSACTIONS__FILTER_SEARCHBY',
  SetTransactionsFilterDate: 'SET__TRANSACTIONS_FILTER_DATE',
  SetTransactionsFilterCards: 'SET__TRANSACTIONS_FILTER_CARDS',
  SetTransactionsFilterGroups: 'SET__TRANSACTIONS_FILTER_GROUPS',
  SetTransactionsFilterCategories: 'SET__TRANSACTIONS_FILTER_CATRGORIES',
  SetTransactionsFilterTypes: 'SET__TRANSACTIONS_FILTER_TYPES',

  UpdateTransaction: 'UPDATE__TRANSACTION',
  UpdateTransactionCompleted: 'UPDATE__TRABSACTION_COMPLETED',
  SetStatusUpdateTransaction: 'UPDATE__STATUS__TRABSACTION',


  SetTransactionsTransaction: 'SET__TRANSACTIONS_TRANSACTION',

  CleanTransactionsFilters: 'CLEAN__TRANSACTIONS_FILTERS',

  DeleteTransactionsInitialData: 'DELETE__TRANSACTIONS_INITIAL_DATA'
};

// #####################################################
// #####################################################

const initialTransactionState = {
  statusFetch: 'initial',
  list: {
    data: [],
  },
  last_transaction: {},

  sortBy: '',
  searchBy: '',
  filterByDate: new Date().toISOString().substr(0, 7) + '-01',
  filterByCards: '',
  filterByGroups: '',
  filterByCategories: '',
  filterByTypes: '',

  transaction: null,
  summary: {
    deposit: {
      amount: 0, operations: 0
    }, debit: {
      amount: 0, operations: 0
    },
    categories: {}
  }
};

// #####################################################
// #####################################################

export const reducer =
  /*persistReducer(
  { storage: storageSession, key: 'cl-transactions', whitelist: ['list'] },*/
  (state = initialTransactionState, action) => {
    switch (action.type) {
      case actionTypes.SetStatusTransactions: {
        const { status } = action.payload;

        return {
          ...state,
          statusFetch: status,
        };
      }

      case actionTypes.GetTransactionsCompleted: {
        const { transactions } = action.payload;
        return {
          ...state,
          list: transactions.data,
          statusFetch: 'success',
        };
      }

      case actionTypes.SetTransactionsFilterSortBy: {
        const { sortBy } = action.payload;

        return {
          ...state,
          sortBy: sortBy,
        };
      }
      case actionTypes.SetTransactionsFilterSearchBy: {
        const { searchBy } = action.payload;

        return {
          ...state,
          searchBy: searchBy,
        };
      }

      case actionTypes.SetTransactionsFilterDate: {
        const { filterByDate } = action.payload;

        return {
          ...state,
          filterByDate: filterByDate,
        };
      }

      case actionTypes.SetTransactionsFilterCards: {
        const { filterByCards } = action.payload;

        return {
          ...state,
          filterByCards: filterByCards,
        };
      }
      case actionTypes.SetTransactionsFilterCategories: {
        const { filterByCategories } = action.payload;

        return {
          ...state,
          filterByCategories: filterByCategories,
        };
      }
      case actionTypes.SetTransactionsFilterGroups: {
        const { filterByGroups } = action.payload;

        return {
          ...state,
          filterByGroups: filterByGroups,
        };
      }


      case actionTypes.SetTransactionsFilterTypes: {
        const { filterByTypes } = action.payload;

        return {
          ...state,
          filterByTypes: filterByTypes,
        };
      }

      case actionTypes.SetTransactionsTransaction: {
        const { transaction } = action.payload;

        return {
          ...state,
          transaction: transaction,
        };
      }

      case actionTypes.CleanTransactionsFilters: {
        return {
          ...state,
          filterByTypes: '',
          filterByGroups: '',
          filterByCategories: '',
          filterByCards: '',
          searchBy: ''

        }
      }



      case actionTypes.UpdateTransactionCompleted: {
        const { transaction } = action.payload;

        const data = state.list.data.map(item => {
          if (item.id === transaction.id) {
            item = { ...item, ...transaction }
          }
          return item;
        })

        return {
          ...state,
          list: {
            data
          },
          //transaction: transaction.data,
          //statusFetch: 'success',
        };
      }


      case actionTypes.DeleteTransactionsInitialData: {
        const x = { ...initialTransactionState };
        x.filterByDate = state.filterByDate
        return x;
      }

      default:
        return state;
    }
  };
//);

// #####################################################
// #####################################################

export const actions = {
  // ------------------------------------------------------------
  requestTransactions: (params) => {
    return { type: actionTypes.GetTransactions, payload: { params } };
  },
  requestTransactionsCompleted: function (transactions) {
    return {
      type: actionTypes.GetTransactionsCompleted,
      payload: { transactions },
    };
  },
  setStatusTransactions: (status) => {
    return { type: actionTypes.SetStatusTransactions, payload: { status } };
  },

  setFilterSortBy: (sortBy) => {
    return {
      type: actionTypes.SetTransactionsFilterSortBy,
      payload: { sortBy },
    };
  },
  setFilterSearchBy: (searchBy) => {
    return {
      type: actionTypes.SetTransactionsFilterSearchBy,
      payload: { searchBy },
    };
  },

  setTransactionsFilterDate: (filterByDate) => {
    return {
      type: actionTypes.SetTransactionsFilterDate,
      payload: { filterByDate },
    };
  },
  setTransactionsFilterCards: (filterByCards) => {
    return {
      type: actionTypes.SetTransactionsFilterCards,
      payload: { filterByCards },
    };
  },
  setTransactionsFilterCategories: (filterByCategories) => {
    return {
      type: actionTypes.SetTransactionsFilterCategories,
      payload: { filterByCategories },
    };
  },

  setTransactionsFilterTypes: (filterByTypes) => {
    return {
      type: actionTypes.SetTransactionsFilterTypes,
      payload: { filterByTypes },
    };
  },

  setTransactionsFilterGroups: (filterByGroups) => {
    return {
      type: actionTypes.SetTransactionsFilterGroups,
      payload: { filterByGroups },
    };
  },
  setTransactionsTransaction: (transaction) => {
    return {
      type: actionTypes.SetTransactionsTransaction,
      payload: { transaction },
    };
  },

  cleanTransactionsFilters: () => {
    return {
      type: actionTypes.CleanTransactionsFilters,
    };
  },



  // ------------------------------------------------------------

  requestUpdateTransaction: (transaction) => {
    return { type: actionTypes.UpdateTransaction, payload: { transaction } };
  },
  requestUpdateTransactionCompleted: (transaction) => {
    return { type: actionTypes.UpdateTransactionCompleted, payload: { transaction } };
  },
  setStatusUpdateTransaction: (status) => {
    return { type: actionTypes.SetStatusUpdateTransaction, payload: { status } };
  },


  requestDeleteTransactionsInitialData: () => {
    return { type: actionTypes.DeleteTransactionsInitialData, payload: {} };
  }

};


// #####################################################
// #####################################################

export function* saga() {
  //listo transaction
  yield takeLatest(
    actionTypes.GetTransactions,
    function* getTransactionsSaga(action) {
      yield put(actions.setStatusTransactions('loading'));
      try {
        const transactions = yield getTransactionsAsync(action.payload);
        yield put(actions.requestTransactionsCompleted(transactions));
      } catch {
        yield put(actions.setStatusTransactions('fail'));
      }
    }
  );


  //--update group
  yield takeLatest(actionTypes.UpdateTransaction, function* getUpdateTranaactionSaga(data) {

    try {

      yield put(actions.requestUpdateTransactionCompleted(data.payload.transaction));
    } catch {

    }
  });
}
