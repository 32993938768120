const loadCrispScript = (callback, { user, company }) => {
    const existingScript = document.getElementById('crispscript');
    if (!existingScript) {
        window.$crisp = [];
        window.$crisp.push(["safe", true])

        window.CRISP_WEBSITE_ID = 'bd171bba-39b0-4ac7-be9d-b5c578741dc3';
        const script = document.createElement('script');
        script.src = 'https://client.crisp.chat/l.js';
        script.id = 'crispscript';
        script.async = 1;
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();


        };
    }
    if (existingScript && callback) callback();

    if (company && company.name && company.name !== '') {
        window.$crisp.push(["set", "user:company", [company.name]]);
        window.$crisp.push(["set", "user:nickname", [company.name]]);
    }

};
export default loadCrispScript;
