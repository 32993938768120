const Resource = require('../Resource');

module.exports = Resource.extend({
  list(params = {}) {
    const master = sessionStorage.getItem('master');
    params['master'] = master;
    const instance = this.prepare();
    const URL = 'services/' + this.jsonToQueryString(params);
    return instance.get(URL);
  },

  retrieve(id) {
    return this.prepare().get(`services/` + id);
  },
  create(body) {
    const master = sessionStorage.getItem('master');
    body['master'] = master;
    return this.prepare().post(`services/`, body);
  },
});
