import { put, takeLatest } from 'redux-saga/effects';
import {
  getServicesAsync,
  createServiceAsync,
  getServiceAsync

} from './servicesCrud';
export const actionTypes = {
  GetServices: 'GET__SERVICES',
  GetServicesAsync: 'GET__SERVICES_ASYNC',
  GetServicesCompleted: 'GET__SERVICES_COMPLETED',
  SetStatusServices: 'SET__STATUS__SERVICES',

  CreateServices: 'CREATE_SERVICES',
  CreateServicesCompleted: 'CREATE_SERVICES_COMPLETED',
  SetStatusCreateServices: 'SET_STATUS_CREATE_SERVICES',

  ResetCreateService: 'RESET__CREATE_SERVICE',

  GetService: 'GET__SERVICE',
  GetServiceCompleted: 'GET__SERVICE_COMPLETED',
  SetStatusService: 'SET__STATUS__SERVICE',


  SetService: 'SET__SERVICE',

  DeleteServicesInitialData: 'DELETE__SERVICES_INITIAL_DATA'
};

// #####################################################
// #####################################################

const initialServiceState = {
  statusFetch: 'initial',
  list: {
    data: [],
  },
  sortBy: 'NameAsc',
  searchBy: '',

  createStatus: 'initial',

  service: null,
  serviceStatus: 'initial',

};

// #####################################################
// #####################################################

export const reducer = /*persistReducer(
  { storage: storageSession, key: 'cl-services', whitelist: ['list'] },
  */ (state = initialServiceState, action) => {
    switch (action.type) {
      case actionTypes.SetStatusServices: {
        const { status } = action.payload;

        return {
          ...state,
          statusFetch: status,
        };
      }

      case actionTypes.GetServicesCompleted: {
        const { services } = action.payload;
        const list = services.data;

        list.data = list.data.map((item) => {
          item.name = '';
          if (item.first_name)
            item.name = (item.first_name + ' ' + item.last_name).trim();
          return item;
        });

        return {
          ...state,
          list,
          statusFetch: 'success',
        };
      }

      case actionTypes.SetServicesFilterSortBy: {
        const { sortBy } = action.payload;

        return {
          ...state,
          sortBy: sortBy,
        };
      }
      case actionTypes.SetServicesFilterSearchBy: {
        const { searchBy } = action.payload;

        return {
          ...state,
          searchBy: searchBy,
        };
      }

      case actionTypes.SetStatusCreateServices: {
        const { status } = action.payload;

        return {
          ...state,
          createStatus: status,
        };
      }

      case actionTypes.ResetCreateService: {
        return {
          ...state,
          service: null,
          createStatus: 'initial',
        };
      }

      case actionTypes.CreateServicesCompleted: {
        const { services } = action.payload;
        return {
          ...state,
          service: services.data,
          createStatus: 'success',
        };
      }

      case actionTypes.SetService: {
        let { service } = action.payload;
        if (typeof service === 'string') {
          service = state.list.data.find((item) => item.id === service);
        }
        return {
          ...state,
          service: service,
        };
      }

      case actionTypes.SetStatusService: {
        const { status } = action.payload;

        return {
          ...state,
          serviceStatus: status,
        };
      }

      case actionTypes.GetServiceCompleted: {
        const { service } = action.payload;
        return {
          ...state,
          service: service.data || state.service,
          statusFetch: service.data ? 'success' : 'fail',
        };
      }

      case actionTypes.DeleteServicesInitialData: {

        const x = { ...initialServiceState };

        return x;

      }


      default:
        return state;
    }
  };
//);

// #####################################################
// #####################################################

export const actions = {
  // ------------------------------------------------------------
  requestServices: () => {
    return { type: actionTypes.GetServices };
  },
  requestServicesAsync: () => {
    return { type: actionTypes.GetServicesAsync };
  },
  requestServicesCompleted: function (services) {
    return { type: actionTypes.GetServicesCompleted, payload: { services } };
  },
  setStatusServices: (status) => {
    return { type: actionTypes.SetStatusServices, payload: { status } };
  },

  setFilterSortBy: (sortBy) => {
    return { type: actionTypes.SetServicesFilterSortBy, payload: { sortBy } };
  },
  setFilterSearchBy: (searchBy) => {
    return {
      type: actionTypes.SetServicesFilterSearchBy,
      payload: { searchBy },
    };
  },

  //Create
  requestCreateServices: (services) => {
    return { type: actionTypes.CreateServices, payload: { services } };
  },
  requestCreateServicesCompleted: function (services) {
    return {
      type: actionTypes.CreateServicesCompleted,
      payload: { services },
    };
  },
  setStatusCreateServices: (status) => {
    return { type: actionTypes.SetStatusCreateServices, payload: { status } };
  },

  requestSetService: function (service) {
    return { type: actionTypes.SetService, payload: { service } };
  },
  resetCreateService: () => {
    return {
      type: actionTypes.ResetCreateService,

    }
  },


  // ------------------------------------------------------------
  requestService: (service) => {
    return { type: actionTypes.GetService, payload: { service } };
  },
  requestServiceCompleted: function (service) {
    return {
      type: actionTypes.GetServiceCompleted,
      payload: { service },
    };
  },

  setStatusService: (status) => {
    return { type: actionTypes.SetStatusService, payload: { status } };
  },

  requestDeleteServicesInitialData: () => {
    return { type: actionTypes.DeleteServicesInitialData, payload: {} };
  }

};

// #####################################################
// #####################################################

export function* saga() {
  yield takeLatest(actionTypes.GetServices, function* getServicesSaga(action) {
    yield put(actions.setStatusServices('initial'));
    try {
      const company = yield getServicesAsync();
      yield put(actions.requestServicesCompleted(company));
    } catch (ex) {
      console.log(ex);
      yield put(actions.setStatusServices('fail'));
    }
  });

  yield takeLatest(
    actionTypes.GetServicesAsync,
    function* getServicesAsyncSaga(action) {
      try {
        const company = yield getServicesAsync();
        yield put(actions.requestServicesCompleted(company));
      } catch { }
    }
  );

  yield takeLatest(
    actionTypes.CreateServices,
    function* createServicesSaga(action) {
      yield put(actions.setStatusCreateServices('initial'));
      try {
        const services = yield createServiceAsync(action.payload.services);
        yield put(actions.requestCreateServicesCompleted(services));
      } catch {
        yield put(actions.setStatusCreateServices('fail'));
      }
    }
  );


  //listo transferences
  yield takeLatest(
    actionTypes.GetService,
    function* getServiceSaga(action) {
      yield put(actions.setStatusService('initial'));

      try {
        const service = yield getServiceAsync(action.payload);
        yield put(actions.requestServiceCompleted(service));
      } catch {
        yield put(actions.setStatusService('fail'));
      }
    }
  );

}
