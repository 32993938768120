import { getCards, activateCards, requestNewCards, requestUpdateCards, getCard, requestNip } from '../../../provider/api/core'

export function getCardsAsync() {
    return getCards();
}

export function getCardAsync(id) {
    return getCard(id);
}


export function activateCardsAsync(params) {
    return activateCards(params);
}

export function requestNewCardsAsync(params) {
    return requestNewCards(params);
}

export function requestUpdateCardsAsync(params) {
    return requestUpdateCards(params);
}



export function requestNipAsync(params) {
    return requestNip(params);
}

