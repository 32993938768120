import axios from "axios";
export const ME_URL = "user";


export function getUserByToken(idUser) {
  // Authorization head should be fulfilled in interceptor.
  //return axios.get(`${ME_URL}offset=0&limit=1111`);
  return axios.get(`${ME_URL}/${idUser}?offset=0&limit=1111`);
  // return axios.get(`${ME_URL}?offset=0&limit=1111`);
}
export function changeUserPasswordAsync(idUser, data) {
  return axios.put(`${ME_URL}/${idUser}`, data);
}