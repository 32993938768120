const Resource = require('../Resource');

module.exports = Resource.extend({
  events() {
    return this.prepare().get(`credentials/events`);
  },
  changePassword(body) {
    return this.prepare().post(`credentials/change-password`, body);
  },

});
