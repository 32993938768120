import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Error404Page from "./error404";


export default function ErrorsPage() {
  return (
    <Routes>
      {/*<Redirect from="/error" exact={true} to="/error/404" />*/}
      <Route path="/error" element={<Navigate replace to={"/error/404"} />} />
      <Route path="/error/404" element={<Error404Page />} />
    </Routes>
  );
}
