

import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';


import { put, takeLatest } from 'redux-saga/effects';
import { getUsersAsync, createUserAsync, updateUserAsync } from './usersCrud';
export const actionTypes = {
  GetUsers: 'GET__USERS',
  GetUsersAsync: 'GET__USERS_ASYNC',
  GetUsersCompleted: 'GET__USERS_COMPLETED',
  SetStatusUsers: 'SET__STATUS__USERS',

  SetUsersFilterSortBy: 'SET__USERS__FILTER_SORTBY',
  SetUsersFilterSearchBy: 'SET__USERS__FILTER_SEARCHBY',



  ActivateUsers: 'ACTIVATE_USERS',
  ActivateUsersCompleted: 'ACTIVATE_USERS_COMPLETED',
  SetStatusActivateUsers: 'SET_STATUS_ACTIVATE_USERS',


  UpdateUsers: 'UPDATE_USERS',
  UpdateUsersCompleted: 'UPDATE_USERS_COMPLETED',

  UpdateUserCompleted: 'UPDATE_USER_COMPLETED',

  SetUser: 'SET__USER'



};

// #####################################################
// #####################################################

const initialCompanyState = {
  statusFetch: 'initial',
  list: {
    data: []
  },
  sortBy: 'NameAsc',
  searchBy: '',


  activateStatus: 'initial',
  activatedUsers: {
    users: []
  },

  user: null
};

// #####################################################
// #####################################################

export const reducer = persistReducer(
  { storage, key: 'cl-users', whitelist: ['statusFetch', 'list'] },
  (state = initialCompanyState, action) => {
    switch (action.type) {
      case actionTypes.SetStatusUsers: {
        const { status } = action.payload;

        return {
          ...state,
          statusFetch: status,
        };
      }

      case actionTypes.GetUsersCompleted: {
        const { users } = action.payload;
        const list = users.data;

        list.data = list.data.map(item => {
          item.name = "";
          if (item.first_name)
            item.name = (item.first_name + " " + item.last_name).trim();
          return item;
        });


        return {
          ...state,
          list,
          statusFetch: 'success',
        };
      }

      case actionTypes.UpdateUserCompleted: {
        const { user } = action.payload;
        const list = state.list;

        list.data = state.list.data.map(item => {
          if (item.id === user.id) {
            item = { ...user }
          }
          return item;
        });


        return {
          ...state,
          list,
          statusFetch: 'success',
        };
      }






      case actionTypes.SetUsersFilterSortBy: {
        const { sortBy } = action.payload;

        return {
          ...state,
          sortBy: sortBy,
        };
      }
      case actionTypes.SetUsersFilterSearchBy: {
        const { searchBy } = action.payload;

        return {
          ...state,
          searchBy: searchBy,
        };
      }





      case actionTypes.SetStatusActivateUsers: {
        const { status } = action.payload;

        return {
          ...state,
          activateStatus: status,
        };
      }

      case actionTypes.ActivateUsersCompleted: {
        const { users } = action.payload;
        return {
          ...state,
          activatedUsers: users.data,
          activateStatus: 'success',
        };
      }


      case actionTypes.SetUser: {
        let { user } = action.payload;
        if (typeof (user) === 'string') {
          user = state.list.data.find(item => item.id === user)
        }
        return {
          ...state,
          user: user,
        };
      }

      default:
        return state;
    }
  }
);

// #####################################################
// #####################################################

export const actions = {
  // ------------------------------------------------------------
  requestUsers: () => {
    return { type: actionTypes.GetUsers };
  },
  requestUsersAsync: () => {
    return { type: actionTypes.GetUsersAsync };
  },
  requestUsersCompleted: function (users) {
    return { type: actionTypes.GetUsersCompleted, payload: { users } };
  },
  setStatusUsers: (status) => {
    return { type: actionTypes.SetStatusUsers, payload: { status } };
  },



  setFilterSortBy: (sortBy) => {
    return { type: actionTypes.SetUsersFilterSortBy, payload: { sortBy } };
  },
  setFilterSearchBy: (searchBy) => {
    return { type: actionTypes.SetUsersFilterSearchBy, payload: { searchBy } };
  },


  //Activate
  requestActivateUsers: (users) => {
    return { type: actionTypes.ActivateUsers, payload: { users } };
  },
  requestActivateUsersCompleted: function (users) {
    return { type: actionTypes.ActivateUsersCompleted, payload: { users } };
  },
  setStatusActivateUsers: (status) => {
    return { type: actionTypes.SetStatusActivateUsers, payload: { status } };
  },

  requestUpdateUsers: (users) => {
    return { type: actionTypes.UpdateUsers, payload: { users } };
  },
  requestUpdateUsersCompleted: function (users) {
    return { type: actionTypes.UpdateUsersCompleted, payload: { users } };
  },


  requestUpdateUserCompleted: function (user) {
    return { type: actionTypes.UpdateUserCompleted, payload: { user } };
  },

  requestSetUser: function (user) {
    return { type: actionTypes.SetUser, payload: { user } };
  },

};

// #####################################################
// #####################################################

export function* saga() {
  yield takeLatest(actionTypes.GetUsers, function* getUsersSaga(action) {
    yield put(actions.setStatusUsers('initial'));
    try {
      const company = yield getUsersAsync();
      yield put(actions.requestUsersCompleted(company));
    } catch {
      yield put(actions.setStatusUsers('fail'));
    }
  });

  yield takeLatest(actionTypes.GetUsersAsync, function* getUsersAsyncSaga(action) {

    try {
      const company = yield getUsersAsync();
      yield put(actions.requestUsersCompleted(company));
    } catch {

    }
  });


  yield takeLatest(actionTypes.ActivateUsers, function* activateUsersSaga(action) {
    yield put(actions.setStatusActivateUsers('initial'));
    try {
      const users = yield createUserAsync(action.payload.users);
      yield put(actions.requestActivateUsersCompleted(users));
    } catch {
      yield put(actions.setStatusActivateUsers('fail'));
    }
  });


  yield takeLatest(actionTypes.UpdateUsers, function* requestUpdateUsersSaga(action) {

    try {
      const users = yield updateUserAsync(action.payload.users);
      yield put(actions.requestUpdateUsersCompleted(users));
    } catch (ex) {
      console.log(ex);
    }
  });
}
