const config = {
  auth0: {
    domain: 'Your Auth Domain',
    clientID: 'Your clientId',
    redirectUri: 'Your callback URL',
  },

  cognito: {
    userPoolId: process.env.REACT_APP_COGNITO_REGION +
    '_' +process.env.REACT_APP_COGNITO_USER_POOL_ID,
    clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
  },
};
 
export default config;
