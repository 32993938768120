import { connect } from 'react-redux';
import logo_icon from '../../assets/images/logo/logo120x120.png';

const roundedCircleStyle = {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    margin: '0px'
};


const LogoComponent = (props) => {
    const { company, size = 40 } = props;

    const logoCompany = company.logo || logo_icon


    return (<img
        className="img-fluid" style={{ ...roundedCircleStyle, width: `${size}px`, height: `${size}px` }} src={logoCompany} alt={company.name} />);
}


export default connect(({ company }) => {
    return {
        company: company.info
    }
}, {})(LogoComponent);