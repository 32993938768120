import { getCompanyInvoices, getInvoicesToken,updateInvoicesTokenAsync,getInvoices,requestUpdateInvoices, getInvoice} from '../../../provider/api/core'

export function requestInvoicesToken(payload) {
    return getInvoicesToken(payload);
}
 
export function updateInvoicesToken(payload) {
    return updateInvoicesTokenAsync(payload);    
}

export function getCompanyInvoicesAsync(payload) {
    return getCompanyInvoices(payload);
}


export function getInvoicesAsync(payload) {
    return getInvoices(payload);
}
 

export function getInvoiceAsync(payload) {
    return getInvoice(payload);
}
 

export function requestUpdateInvoicesAsync(payload) {
    return requestUpdateInvoices(payload);
}
 