import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import * as user from '../../../../app/modules/UserProfile/_redux/userRedux';

import React, { useState } from 'react';

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
} from 'reactstrap';

import { toast } from 'react-toastify';


import {
  ResetPasswordEvent,
  ResetPasswordErrorEvent,
  ResetPasswordConfirmErrorEvent,
  ResetPasswordConfirmEvent,
} from '../../../provider/segment/events/auth';
import { confirmPassword, forgotPassword } from '../_redux/authCrud';

import { Link } from 'react-router-dom';

const ResetPassword = (props) => {

  const cover = process.env.REACT_APP_IMAGE_URL+'cover_full.jpg';
  const logo = process.env.REACT_APP_IMAGE_URL+'logo_small.png';

  
  const [destination, setDestination] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);

  const [isSentCode, setIsSentCode] = useState(false);

  const forgotPasswordClick = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    forgotPassword({ email })
      .then((r) => {
        setTimeout(() => {
          toast.success('INTRODUCE EL CODIGO QUE ENVIAMOS A TU EMAIL ');
          
        }, 200);
        setDestination(r.CodeDeliveryDetails.Destination);
        ResetPasswordEvent({ email, data: r.CodeDeliveryDetails });
        setIsSentCode(true);
      })
      .catch((error) => {
        setTimeout(() => {
          toast.error(error.message);
        }, 200);
        ResetPasswordErrorEvent({ email, error });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmPasswordClick = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    confirmPassword({ email, code, password })
      .then((r) => {
        setTimeout(() => {
          toast.success('SE HA ACTUALIZADO TU CONTRASEÑA EXITOSAMEMTE');

        }, 200);
        
        ResetPasswordConfirmEvent({ email, r });

        setTimeout(() => {
          window.location = '/auth/login'
        },1500)
        
      })
      .catch((error) => {
        setTimeout(() => {
          toast.error(error.message);
        }, 200);
        ResetPasswordConfirmErrorEvent({ email, error });
      })
      .finally(() => {
        setLoading(false);
      });
  };



  const isInvalid = code.length !== 6 || isNaN(code) || password.length < 6

  return (
    <Container fluid={true}>
      <Row>
        <Col
          xl="8"
          className="b-center bg-size"
          style={{
            backgroundImage: `url(${cover})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'block',
          }}
        >
          <img
            className="bg-img-cover bg-center"
            style={{ display: 'none' }}
            src={cover}
            alt="looginpage"
          />
        </Col>
        <Col xl="4" className="p-0">
          <div className="login-card " style={{minHeight:'900px', maxHeight:'900px'}}>
            <div>
              <div className="login-main feature-products ">
                 
                <br />
                <br />
                <br />
                <br />
                <center>
                  <img className="img-fluid" src={logo} alt="" width="250" />
                </center>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <h3 className="mb-0 text-dark">
                {isSentCode?(<>Reestablece
                  <br />tu contraseña</>):(<>Recupera
                  <br />tu contraseña</>)}
                </h3>

                <Label className="col-form-label text-secondary">
                  {isSentCode
                    ? 'Introduce el código de verificación que llegó a tu email'
                    : 'Introduce el email asociado a tu cuenta'}
                </Label>

                <br />
                <br />

                <Form className="mb-6 theme-form" id="signinForm">
                  {isSentCode ? (
                    <>
                      <FormGroup>
                        <h5 className="pt-2 pb-2  text-dark">{destination}</h5>
                      </FormGroup>

                      <FormGroup>
                        <Label className="col-form-label  text-dark">
                          Código de verificación
                        </Label>
                        <Input
                          className="form-control  form-control-md"
                          type="text"
                          onChange={(e) => setCode(e.target.value)}
                          placeholder="* * * * * "
                          tabIndex="2"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label className="col-form-label  text-dark">
                          Ingresa tu nueva contraseña
                        </Label>
                        <Input
                          className="form-control  form-control-md"
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="* * * * * * * * * * * * *  "
                          tabIndex="2"
                        />
                      </FormGroup>

                      <br />

                      <Button
                        block
                        className="btn-pill btn-air-primary p-3 m-2 mb-5"
                        type="submit"
                        color="dark"
                        onClick={confirmPasswordClick}
                        disabled={loading || isInvalid}
                        tabIndex="3"
                      >
                        {loading ? (
                          <>
                            <Spinner
                              style={{ width: '15px', height: '15px' }}
                            />{' '}
                            {'Reestableciendo contraseña ... '}
                          </>
                        ) : (
                          'Reestablecer contraseña'
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <FormGroup>
                        <Label className="col-form-label  text-dark">Email</Label>
                        <Input
                          className="form-control form-control-md"
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          defaultValue={email}
                          tabIndex="1"
                          
                        />
                      </FormGroup>

                      <br />

                      <Button
                        block
                        className="btn-pill btn-air-primary p-3 m-2 mb-5"
                        type="submit"
                        color="dark"
                        onClick={forgotPasswordClick}
                        disabled={loading}
                        tabIndex="3"
                      >
                        {loading ? (
                          <>
                            <Spinner
                              style={{ width: '15px', height: '15px' }}
                            />{' '}
                            {'Enviando email'}
                          </>
                        ) : (
                          'Enviar código de verificación'
                        )}
                      </Button>
                      <br />
                <br /> <br />
                <br /> 


                    </>
                    
                  )}


                
                  
                  <hr />
                  <div className="text-right pt-1">
                    <Link className="text-primary" to="/auth/login">
                      Iniciar sesión
                    </Link>
                  </div><br/><br/>

                  <div className="text-center">
                  <small>{process.env.REACT_APP_TERMS}. Todos los derechos reservados {new Date().getFullYear()}. V2</small>
                  </div>                

                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default injectIntl(
  connect(null, { ...auth.actions, ...user.actions })(ResetPassword)
);
