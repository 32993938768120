import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import loadCrispScript from './loadchat';
const Footer = (props) => {
  const { user, company } = props;




  useEffect(() => {
    loadCrispScript(() => {

    }, { user, company });
  }, [user, company]);

  if (company.status === 'initial') {
    return <></>
  }


  return (
    <Fragment>
      <>
      </>
    </Fragment>
  );
}

export default connect(
  ({ user, company }) => {
    return {
      user: user,
      privileges: user.privileges,
      company: company.info
    };
  },
  {

  }
)(Footer);
