import { getGroups, updateGroups, createGroups, deleteGroups } from '../../../provider/api/core'

export function getGroupsAsync() {
    return getGroups();
}


export function updateGroupAsync(data) {
    return updateGroups(data);
}
 

export function createGroupAsync(data) {
    return createGroups(data);
}
 

export function deleteGroupAsync(data) {
    return deleteGroups(data);
}
 