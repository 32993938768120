import React from 'react';

import * as companyRedux from '../../Company/_redux/companyRedux';
import * as cardsRedux from '../../Cards/_redux/cardsRedux';
import * as transactionsRedux from '../../Transactions/_redux/transactionsRedux';
import * as dashboardRedux from '../../Dashboard/_redux/dashboardRedux';
import * as servicesRedux from '../../Services/_redux/servicesRedux';

import { connect } from 'react-redux';

import Select from 'react-select';

import { BsBuilding } from 'react-icons/bs';
import { BiGasPump } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const CompaniesComponent = (props) => {
  const navigate = useNavigate();

  const {
    info,
    master,

    requestSetCompanyMasterSelected,

    requestDeleteCardsInitialData,
    requestDeleteTransactionsInitialData,
    requestDeleteDashboardSummary,
    requestDeleteServicesInitialData,
  } = props;

  if (!master) return <></>;

  const options = info.masters
    ? info.masters.map((item) => ({
      value: item.id,
      label: (
        <div style={{ overflow: 'hidden' }}>
          <label className="text-nowrap p-0 pt-1">
            {item.product === 'gas' ? (
              <BiGasPump color="grey" />
            ) : (
              <BsBuilding color="grey" />
            )}
            &nbsp;&nbsp;{item.name}
          </label>
        </div>
      ),
    }))
    : [];

  if (!(info.masters && info.masters.length > 0)) return <></>;

  const m = options.find((i) => i.value === master);
  const defaultCompany = m.label ? m.label : <></>;

  /* (
     <>
       <div style={{ overflow: 'hidden', maxHeight: '20px' }}>
         <BsBuilding color="grey" />
         &nbsp;&nbsp;{info.masters.find(i => i.id === master)?.name}
       </div>
     </>
   );*/

  const updateMaster = (item) => {
    requestSetCompanyMasterSelected(item);
    sessionStorage.setItem('master', item);

    requestDeleteCardsInitialData();
    requestDeleteTransactionsInitialData();
    requestDeleteDashboardSummary();
    requestDeleteServicesInitialData();

    navigate('/dashboard');
  };

  return (
    <>
      <Select
        id="defaultCompany"
        className="tour-step-company"
        placeholder={defaultCompany}
        isSearchable={false}
        options={options}
        onChange={(item) => {
          if (master !== item.value) {
            updateMaster(item.value);
          }
        }}
        styles={{
          width: '200px',
          maxWidth: '200px',
          //...styles,
          control: (base, state) => ({
            ...base,

            '&:hover': { borderColor: '#FCFCFC' }, // border style on hover
            border: '1px solid #EEE', // default border color
            // fontSize:'20px',
            boxShadow: 'none', // no box-shadow
            //backgroundColor:'transparent',
            paddingLeft: '10px',
            paddingTop: '5px',
            paddingBottom: '5px',
            marginLeft: '10px',
            marginTop: '5px',
            backgroundColor: 'transparent',

            //color:'primary'
          }),
          option: (provided, state) => {
            return {
              ...provided,

              color: state.isSelected ? 'white' : '#444',
              backgroundColor: state.isSelected
                ? '#212530'
                : state.isFocused
                  ? '#EEE'
                  : 'white',
              padding: 10,
            };
          },
        }}
        defaultValue={master}
      />
    </>
  );
};

export default connect(
  ({ company }) => {
    return {
      funds: company.balance.funds,
      info: company.info,
      fetchStatus: company.fetchStatus,
      master: company.master,
    };
  },
  {
    ...companyRedux.actions,
    ...cardsRedux.actions,
    ...transactionsRedux.actions,
    ...dashboardRedux.actions,
    ...servicesRedux.actions,
  }
)(CompaniesComponent);
