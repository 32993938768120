import { getCompanyInfo, getCompanyFunds, getCompanyActivity, getCompanyAccess, getCompanyInvoices} from '../../../provider/api/core'

export function getCompanyByIdAsync() {
    return getCompanyInfo();
}

export function getCompanyFundsAsync() {
    return getCompanyFunds();
}

export function getCompanyActivityAsync() {
    return getCompanyActivity();
}

export function getCompanyAccessAsync() {
    return getCompanyAccess();
}

export function getCompanyInvoicesAsync() {
    return getCompanyInvoices();
}