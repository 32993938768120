import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MENUITEMS } from './menu';
import { ArrowRight, ArrowLeft, Grid, X } from 'react-feather';
import { Link } from 'react-router-dom';

import { Badge, UncontrolledTooltip } from 'reactstrap';
import LogoComponent from './logo';

const Sidebar = (props) => {
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(false);
  const wrapper = useSelector((content) => 'compact-wrapper');

  useEffect(() => {
    document.querySelector('.left-arrow').classList.add('d-none');

    window.addEventListener('resize', handleResize);
    handleResize();

    const currentUrl = window.location.pathname;
    if (mainmenu && mainmenu.map)
      mainmenu.map((items) => {
        items.Items.filter((Items) => {
          if (Items.path === currentUrl) setNavActive(Items);
          if (!Items.children) return false;
          Items.children.filter((subItems) => {
            if (subItems.path === currentUrl) setNavActive(subItems);
            if (!subItems.children) return false;
            subItems.children.filter((subSubItems) => {
              if (subSubItems.path === currentUrl) {
                setNavActive(subSubItems);
                return true;
              } else {
                return false;
              }
            });
            return subItems;
          });
          return Items;
        });
        return items;
      });

    return () => {
      window.removeEventListener('resize', handleResize);
    };

    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };

  const setNavActive = (item) => {
    MENUITEMS.map((menuItems) => {
      menuItems.Items.filter((Items) => {
        if (Items !== item) Items.active = false;
        if (Items.children && Items.children.includes(item))
          Items.active = true;
        if (Items.children) {
          Items.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return Items;
      });
      return menuItems;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector('.page-header').className =
        'page-header close_icon';
      document.querySelector('.sidebar-wrapper').className =
        'sidebar-wrapper close_icon ';
      if (document.querySelector('.mega-menu-container'))
        document
          .querySelector('.mega-menu-container')
          .classList.remove('d-block');
      if (item.type === 'sub') {
        document.querySelector('.page-header').className = 'page-header ';
        document.querySelector('.sidebar-wrapper').className =
          'sidebar-wrapper ';
      }
    }

    if (!item.active) {
      MENUITEMS.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      document.querySelector('.right-arrow').classList.add('d-none');
      document.querySelector('.left-arrow').classList.remove('d-none');
    } else {
      setMargin((margin) => (margin += -width));
      document.querySelector('.left-arrow').classList.remove('d-none');
    }
  };

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      document.querySelector('.left-arrow').classList.add('d-none');
      document.querySelector('.right-arrow').classList.remove('d-none');
    } else {
      setMargin((margin) => (margin += width));
      document.querySelector('.right-arrow').classList.remove('d-none');
    }
  };

  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector('.page-header').className =
        'page-header close_icon';
      document.querySelector('.sidebar-wrapper').className =
        'sidebar-wrapper close_icon ';
    } else {
      setSidebartoogle(!toggle);
      document.querySelector('.page-header').className = 'page-header';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
    }
  };

  const responsiveSidebar = () => {
    document.querySelector('.page-header').className = 'page-header close_icon';
    document.querySelector('.sidebar-wrapper').className =
      'sidebar-wrapper close_icon';
  };

  return (
    <Fragment>
      <div className="sidebar-wrapper close_icon">
        <div className="logo-wrapper">
          <a
            href="#action"
            className="back-btn text-light"
            onClick={() => responsiveSidebar()}
          >
            <X />
          </a>
          <div
            className="toggle-sidebar"
            onClick={() => openCloseSidebar(sidebartoogle)}
          >
            <Grid className="status_toggle middle sidebar-toggle" />
          </div>
        </div>
        <div className="logo-icon-wrapper">
          <Link
            to="/company"
            href="#action"
            onClick={() => toggletNavActive(MENUITEMS[0].Items[0])}
          >
            <LogoComponent />
          </Link>
        </div>
        <nav className="sidebar-main">

          <div className="left-arrow" onClick={scrollToLeft}>
            <ArrowLeft />
          </div>
          <div
            id="sidebar-menu"
            style={
              wrapper === 'horizontal-wrapper'
                ? { marginLeft: margin + 'px' }
                : { margin: '0px' }
            }
          >
            <div className=' d-block d-sm-none text-center pt-4'>
              <Link
                to="/company"
                href="#action"
                onClick={() => toggletNavActive(MENUITEMS[0].Items[0])}
              >
                <LogoComponent size='80' />
              </Link>
            </div>
            <ul className="sidebar-links custom-scrollbar">
              <li className="back-btn">
                <div className="mobile-back text-right">
                  <span>{'Back'}</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>

                </div>
              </li>
              {MENUITEMS.map((Item, i) => (
                <Fragment key={i}>

                  {Item.Items.map((menuItem, i) => (
                    <li className={"sidebar-list " + (menuItem.only_small ? 'd-xs-block d-lg-none' : '')} key={i} id={'topTitle' + i}>
                      {menuItem.type === 'link' && !menuItem.hidden ? (
                        <Link
                          to={menuItem.path}
                          className={`sidebar-link sidebar-title link-nav   ${menuItem.active ? 'active' : ''
                            }`}
                          href="#action"
                          onClick={() => toggletNavActive(menuItem)}
                        >
                          {menuItem.icon ? <menuItem.icon /> : <>{menuItem.image}<Badge color="danger">Nuevo</Badge><span>&nbsp;&nbsp;</span></>}
                          <span>{menuItem.title}</span>
                        </Link>
                      ) : (
                        ''
                      )}

                      {/*offset="-130px"*/}
                      <UncontrolledTooltip
                        placement="bottom"

                        target={'topTitle' + i}
                      >
                        {menuItem.title}
                      </UncontrolledTooltip>
                    </li>
                  ))}
                </Fragment>
              ))}
            </ul>
          </div>
          <div className="right-arrow" onClick={scrollToRight}>
            <ArrowRight />
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default Sidebar;
