

import { put, takeLatest } from 'redux-saga/effects';
import { getCardsAsync, activateCardsAsync, requestNewCardsAsync, requestUpdateCardsAsync, getCardAsync } from './cardsCrud';
export const actionTypes = {
  GetCards: 'GET__CARDS',
  GetCardsAsync: 'GET__CARDS_ASYNC',
  GetCardsCompleted: 'GET__CARDS_COMPLETED',
  SetStatusCards: 'SET__STATUS__CARDS',

  SetCardsFilterSortBy: 'SET__CARDS__FILTER_SORTBY',
  SetCardsFilterSearchBy: 'SET__CARDS__FILTER_SEARCHBY',
  SetCardsFilterLayoutView: 'SET__CARDS__FILTER_LAYOUTVIEW',
  SetCardsFilterTeam: 'SET__CARDS__FILTER_TEAM',

  SetCardsSummaryView: 'SET__CARDS__SUMMARY_VIEW',


  ActivateCards: 'ACTIVATE_CARDS',
  ActivateCardsCompleted: 'ACTIVATE_CARDS_COMPLETED',
  SetStatusActivateCards: 'SET_STATUS_ACTIVATE_CARDS',


  RequestNewCards: 'REQUEST_NEW_CARDS',
  RequestNewCardsCompleted: 'REQUEST_NEW_CARDS_COMPLETED',

  RequestUpdateCards: 'REQUEST_UPDATE_CARDS',
  UpdateCardsCompleted: 'REQUEST_UPDATE_CARDS_COMPLETED',
  SetUpdateStatusCards: 'SET__UPDATE_STATUS__CARDS',


  GetCard: 'GET__CARD',
  GetCardAsync: 'GET__CARD_ASYNC',
  GetCardCompleted: 'GET__CARD_COMPLETED',
  SetStatusCard: 'SET__STATUS__CARD',


  DeleteCardsInitialData: 'DELETE__CARDS_INITIAL_DATA'
};

// #####################################################
// #####################################################

const initialCompanyState = {
  statusFetch: 'initial',
  updateStatusFetch: '',
  list: {
    data: []
  },
  sortBy: '',
  searchBy: '',
  layoutView: 'grid',
  summaryView: false,
  team: '',


  activateStatus: 'initial',
  activatedCards: {
    cards: []
  },

  cardStatusFetch: 'initial',
  card: {}



};

// #####################################################
// #####################################################

export const reducer =
  (state = initialCompanyState, action) => {
    switch (action.type) {
      case actionTypes.SetStatusCards: {
        const { status } = action.payload;

        return {
          ...state,
          statusFetch: status,
        };
      }

      case actionTypes.SetStatusCard: {
        const { status } = action.payload;

        return {
          ...state,
          cardStatusFetch: status,
        };
      }

      case actionTypes.SetUpdateStatusCards: {
        const { status } = action.payload;

        return {
          ...state,
          updateStatusFetch: status,
        };
      }


      case actionTypes.GetCardsCompleted: {
        const { cards } = action.payload;
        const list = cards.data;

        list.data = list.data.map(item => {
          item.name = "";
          if (item.first_name)
            item.name = (item.first_name + " " + item.last_name).trim();
          return item;
        });


        return {
          ...state,
          list,
          statusFetch: 'success',
        };
      }


      case actionTypes.GetCardCompleted: {
        const { card } = action.payload;
        const cardItem = card.data;
        if (cardItem.object === 'card') {
          state.list.data.forEach((item, index) => {
            if (item.id === cardItem.id) {
              cardItem.name = "";
              if (cardItem.first_name)
                cardItem.name = (cardItem.first_name + " " + cardItem.last_name).trim();
              state.list.data[index] = cardItem;
            }
          });
        }
        return {
          ...state,
          updateStatusFetch: 'success',
        };
      }



      case actionTypes.SetCardsFilterSortBy: {
        const { sortBy } = action.payload;

        return {
          ...state,
          sortBy: sortBy,
        };
      }
      case actionTypes.SetCardsFilterSearchBy: {
        const { searchBy } = action.payload;

        return {
          ...state,
          searchBy: searchBy,
        };
      }

      case actionTypes.SetCardsFilterTeam: {
        const { team } = action.payload;

        return {
          ...state,
          team: team,
        };
      }

      case actionTypes.SetCardsFilterLayoutView: {
        const { layoutView } = action.payload;

        return {
          ...state,
          layoutView: layoutView,
        };
      }


      case actionTypes.SetCardsSummaryView: {
        const { summaryView } = action.payload;

        return {
          ...state,
          summaryView: summaryView,
        };
      }



      case actionTypes.SetStatusActivateCards: {
        const { status } = action.payload;

        return {
          ...state,
          activateStatus: status,
        };
      }

      case actionTypes.ActivateCardsCompleted: {
        const { cards } = action.payload;
        return {
          ...state,
          activatedCards: cards.data,
          activateStatus: 'success',
        };
      }

      case actionTypes.UpdateCardsCompleted: {
        const { card } = action.payload;
        const cardItem = card.data;
        if (cardItem.object === 'card') {
          state.list.data.forEach((item, index) => {
            if (item.id === cardItem.id) {
              cardItem.name = "";
              if (cardItem.first_name)
                cardItem.name = (cardItem.first_name + " " + cardItem.last_name).trim();
              state.list.data[index] = cardItem;
            }
          });
        }
        return {
          ...state,
          updateStatusFetch: 'success',
        };
      }


      case actionTypes.DeleteCardsInitialData: {
        return initialCompanyState;
      }

      default:
        return state;
    }
  }


// #####################################################
// #####################################################

export const actions = {
  // ------------------------------------------------------------
  requestCards: () => {
    return { type: actionTypes.GetCards };
  },
  requestCardsAsync: () => {
    return { type: actionTypes.GetCardsAsync };
  },
  requestCardsCompleted: function (cards) {
    return { type: actionTypes.GetCardsCompleted, payload: { cards } };
  },
  setStatusCards: (status) => {
    return { type: actionTypes.SetStatusCards, payload: { status } };
  },


  requestCard: (id) => {
    return { type: actionTypes.GetCard, payload: { id } };
  },
  requestCardAsync: (id) => {
    return { type: actionTypes.GetCardAsync, payload: { id } };
  },
  requestCardCompleted: function (card) {
    return { type: actionTypes.GetCardCompleted, payload: { card } };
  },
  setStatusCard: (status) => {
    return { type: actionTypes.SetStatusCard, payload: { status } };
  },



  setFilterSortBy: (sortBy) => {
    return { type: actionTypes.SetCardsFilterSortBy, payload: { sortBy } };
  },
  setFilterSearchBy: (searchBy) => {
    return { type: actionTypes.SetCardsFilterSearchBy, payload: { searchBy } };
  },
  setFilterLayoutView: (layoutView) => {
    return { type: actionTypes.SetCardsFilterLayoutView, payload: { layoutView } };
  },
  setFilterTeam: (team) => {
    return { type: actionTypes.SetCardsFilterTeam, payload: { team } };
  },
  setSummaryView: (summaryView) => {
    return { type: actionTypes.SetCardsSummaryView, payload: { summaryView } };
  },

  //Activate
  requestActivateCards: (card) => {
    return { type: actionTypes.ActivateCards, payload: { card } };
  },
  requestActivateCardsCompleted: function (cards) {
    return { type: actionTypes.ActivateCardsCompleted, payload: { cards } };
  },
  setStatusActivateCards: (status) => {
    return { type: actionTypes.SetStatusActivateCards, payload: { status } };
  },

  requestNewCards: (cards) => {
    return { type: actionTypes.RequestNewCards, payload: { cards } };
  },
  requestNewCardsCompleted: function (cards) {
    return { type: actionTypes.RequestNewCardsCompleted, payload: { cards } };
  },


  requestUpdateCards: (card) => {
    return { type: actionTypes.RequestUpdateCards, payload: { card } };
  },
  requestUpdateCardsCompleted: function (card) {
    return { type: actionTypes.UpdateCardsCompleted, payload: { card } };
  },
  setUpdateStatusCards: (status) => {
    return { type: actionTypes.SetUpdateStatusCards, payload: { status } };
  },



  requestDeleteCardsInitialData: () => {
    return { type: actionTypes.DeleteCardsInitialData, payload: {} };
  }

};

// #####################################################
// #####################################################

export function* saga() {
  yield takeLatest(actionTypes.GetCards, function* getCardsSaga(action) {
    yield put(actions.setStatusCards('initial'));
    try {
      const company = yield getCardsAsync();
      yield put(actions.requestCardsCompleted(company));
    } catch (ex) {
      console.log(ex);
      yield put(actions.setStatusCards('fail'));
    }
  });

  yield takeLatest(actionTypes.GetCardsAsync, function* getCardsSaga(action) {

    try {
      const company = yield getCardsAsync();
      yield put(actions.requestCardsCompleted(company));
    } catch {

    }
  });


  yield takeLatest(actionTypes.GetCard, function* getCardSaga(action) {
    yield put(actions.setStatusCard('initial'));
    try {
      const card = yield getCardAsync();
      yield put(actions.requestCardCompleted(card));
    } catch {
      yield put(actions.setStatusCard('fail'));
    }
  });

  yield takeLatest(actionTypes.GetCardAsync, function* getCardSaga(action) {

    try {
      const card = yield getCardAsync(action.payload.id);
      yield put(actions.requestCardCompleted(card));
    } catch (ex) {
      console.log(ex);
    }
  });


  yield takeLatest(actionTypes.ActivateCards, function* activateCardsSaga(action) {
    yield put(actions.setStatusActivateCards('initial'));
    try {
      const cards = yield activateCardsAsync(action.payload.card);
      yield put(actions.requestActivateCardsCompleted(cards));
    } catch {
      yield put(actions.setStatusActivateCards('fail'));
    }
  });


  yield takeLatest(actionTypes.RequestNewCards, function* requestNewCardsSaga(action) {

    try {
      const cards = yield requestNewCardsAsync(action.payload.cards);
      yield put(actions.requestNewCardsCompleted(cards));
    } catch (ex) {
      console.log(ex);
    }
  });



  yield takeLatest(actionTypes.RequestUpdateCards, function* requestUpdateCardsSaga(action) {
    yield put(actions.setUpdateStatusCards('initial'));

    try {
      const cards = yield requestUpdateCardsAsync(action.payload.card);
      yield put(actions.requestUpdateCardsCompleted(cards));
    } catch (ex) {
      yield put(actions.setUpdateStatusCards('fail'));
    }
  });
}
