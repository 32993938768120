const Resource = require('../Resource');

module.exports = Resource.extend({

  create(body) {
    const master = sessionStorage.getItem('master');
    body['master'] = master;
    return this.prepare().post(`transferences/`, body);
  },

  retrieve(id) {
    return this.prepare().get(`transferences/` + id);
  },
});
