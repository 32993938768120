const Resource = require('../Resource');

module.exports = Resource.extend({
  list() {
    return this.prepare().get(`users/`);
  },
  retrieve(id) {
    return this.prepare().get(`users/${id}`);
  },
  create(body) {
    return this.prepare().post(`users/`, body);
  },
  update(id, body) {
    return this.prepare().post(`users/${id}`, body);
  },
  delete(id) {
    return this.prepare().delete(`users/${id}`);
  },
});
