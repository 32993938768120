
import ApiCore from './sdk';
import { getCurrentUser } from '../cognito/auth';

const apicore = new ApiCore('');


const getSession = () => {
    return new Promise((resolve, reject) => {
        const user = getCurrentUser();

        if (!user) {
            return reject(null);
        }
        user
            .getSession((error, session) => {
                if (error) {
                    reject(error);
                } else if (!session.isValid()) {
                    reject(null);
                } else {
                    resolve(session.getIdToken().getJwtToken());
                }
            })
    });
}

const prepareClient = async () => {
    const session = await getSession();
    apicore.setApiKey(session);
    return apicore;
}

export const getCompanyInfo = async () => {
    const client = await prepareClient();
    const response = await client.Companies.list();
    return response;
}

export const getCompanyFunds = async () => {
    const client = await prepareClient();
    const response = await client.Balance.list();
    return response;
}


export const getCompanyActivity = async () => {
    const client = await prepareClient();
    const response = await client.accounts.activity();
    return response;
}


export const getCompanyAccess = async () => {
    const client = await prepareClient();
    const response = await client.Credentials.events();
    return response;
}




export const getCompanyInvoices = async () => {
    const client = await prepareClient();
    const response = await client.Invoices.list();
    return response;
}



export const getInvoices = async () => {
    const client = await prepareClient();
    const response = await client.Invoices.providerInvoices();
    return response;
}

export const getInvoice = async (param) => {
    const client = await prepareClient();
    const response = await client.Invoices.providerInvoice(param);
    return response;
}





export const requestUpdateInvoices = async (data) => {
    throw new Error('not implemented')
}





export const getCards = async () => {
    const client = await prepareClient();
    const response = await client.Cards.list();
    return response;
}


export const getCard = async (id) => {
    const client = await prepareClient();
    const response = await client.Cards.retrieve(id);
    return response;
}


export const activateCards = async (params) => {
    const client = await prepareClient();
    const response = await client.Cards.create(params);
    return response;
}


export const requestNewCards = async (payload) => {
    const client = await prepareClient();
    const response = await client.Cards.requestNewCards(payload);
    return response;
}
export const requestUpdateCards = async (payload) => {
    const client = await prepareClient();
    const response = await client.Cards.update(payload['id'], payload);
    return response;
}
export const requestNip = async (payload) => {
    const client = await prepareClient();
    const response = await client.Cards.nip(payload['id'], payload);
    return response;
}




export const getOrders = async () => {
    const client = await prepareClient();
    const response = await client.orders.list();
    return response;
}
export const updateOrders = async (data) => {
    const client = await prepareClient();
    const response = await client.orders.update(data);
    return response;
}



export const getTransactions = async (payload) => {
    let { params } = payload;
    if (!params) params = {};

    const client = await prepareClient();
    const response = await client.Transactions.list(params);
    return response;
}


export const updateTransaction = async (data) => {
    const client = await prepareClient();
    const response = await client.Transactions.update(data['id'], data);
    return response;
}




export const getGroups = async () => {
    const client = await prepareClient();
    const response = await client.Teams.list();
    return response;
}
export const updateGroups = async (data) => {
    const client = await prepareClient();
    const response = await client.Teams.update(data['id'], data);
    return response;
}

export const createGroups = async (data) => {
    const client = await prepareClient();
    const response = await client.Teams.create(data);
    return response;
}

export const deleteGroups = async (data) => {
    const client = await prepareClient();
    const response = await client.Teams.update(data['id'], {
        status: 'deleted'
    });
    return response;
}




export const getDashboardSummary = async (payload) => {
    let { params } = payload;
    if (!params) params = {};
    const client = await prepareClient();
    const response = await client.Transactions.list(params);
    return response;
}

//..


export const getTransferences = async (payload) => {
    let { params } = payload;
    if (!params) params = {};
    const client = await prepareClient();
    const response = await client.Transferences.list(params);
    return response;
}


export const getOrder = async (payload) => {
    let { order } = payload;
    if (!order) order = {};
    const client = await prepareClient();
    const response = await client.Transferences.retrieve(order.id);
    return response;
}


export const deposit = async (payload) => {
    const client = await prepareClient();
    const response = await client.Transferences.create(payload);
    return response;
}



export const debit = async (payload) => {
    const client = await prepareClient();
    const response = await client.Transferences.create(payload);
    return response;
}


//---Credentials




export const getUsers = async () => {
    const client = await prepareClient();
    const response = await client.Users.list();
    return response;
}


export const createUser = async (params) => {
    const client = await prepareClient();
    const response = await client.Users.create(params);
    return response;
}
export const updateUser = async (payload) => {
    const client = await prepareClient();
    const response = await client.Users.update(payload['id'], payload);
    return response;
}


export const getInvoicesToken = async (payload) => {
    const client = await prepareClient();
    const response = await client.Invoices.token();
    return response;
}



export const updateInvoicesTokenAsync = async (payload) => {
    const client = await prepareClient();
    const response = await client.Companies.update(payload['id'], payload);
    return response;
}




export const requestStatement = async (payload) => {
    const client = await prepareClient();
    const response = await client.Statements.list(payload);
    return response;
}





//Credentials
export const changePassword = async (payload) => {
    const client = await prepareClient();
    const response = await client.Credentials.changePassword(payload);
    return response;
}



//Services
export const getServices = async (params) => {
    const client = await prepareClient();
    const response = await client.Services.list(params);
    return response;
}
export const getService = async (payload) => {
    let { service } = payload;
    if (!service) service = {};
    const client = await prepareClient();
    const response = await client.Services.retrieve(service.id);
    return response;
}
export const createService = async (payload) => {
    const client = await prepareClient();
    const response = await client.Services.create(payload);
    return response;
}
