/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { MainRoutes } from "../app/MainRoutes";
import { I18nProvider } from "../I18n/I18nProvider";
//import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
//import { ClearCacheProvider } from 'react-clear-cache';
//import { SnackbarProvider } from 'notistack';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const NotifierProvider = ({ children }) => {

  return (<>
    <ToastContainer />
    {children}
  </>)

}


const ThemeProvider = ({ children }) => {

  const mode = localStorage.getItem('layout_mode') || 'light'
  useEffect(() => {
    document.body.className = "dark-sidebar " + mode;

  }, [mode]);
  return (<>

    {children}
  </>)

}


export default function App({ store, persistor, basename }) {


  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={null}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        {/*<React.Suspense fallback={<LayoutSplashScreen />}>*}
           {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          {/*<MaterialThemeProvider>*/}
          <ThemeProvider>
            {/* This library clean cache if isn´t last version */}
            {/*<ClearCacheProvider duration={10 * 60 * 1000} auto={true}>*/}
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              {/* Render routes with provided `Layout`. */}
              {/*<SnackbarProvider maxSnack={4} >*/}
              <NotifierProvider>
                <MainRoutes />


              </NotifierProvider>
              {/*</SnackbarProvider>*/}
            </I18nProvider>
          </ThemeProvider>
          {/*</ClearCacheProvider>*/}
          {/*</MaterialThemeProvider>*/}
        </BrowserRouter>
        {/*</React.Suspense>*/}
      </PersistGate>
    </Provider>
  );
}
