const Resource = require('../Resource');

module.exports = Resource.extend({
  list(params) {
    const master = sessionStorage.getItem('master');
    params.master = master;
    const instance = this.prepare();
    const URL = 'statements/' + this.jsonToQueryString(params);
    return instance.get(URL);
  },

  retrieve(id) {
    return this.prepare().get(`statements/` + id);
  },

});
