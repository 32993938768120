import React, { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authRedux from './modules/Auth/_redux/authRedux';
import * as companyRedux from './modules/Company/_redux/companyRedux';

import * as usersRedux from './modules/Users/_redux/usersRedux';
import * as groupsRedux from './modules/Groups/_redux/groupsRedux';

import { getRoutes } from './_helpers/utils';

import ScrollToTop from './components/ScrollToTop';
import AnimationComponent from './components/Animation';

const Loading = (props) => {
  const { startDate, lbl } = props;

  const [title, setTitle] = useState('');

  useEffect(() => {
    //Enable timeout
    if ((lbl || '').indexOf('success') >= 0) {
      const timer = setTimeout(() => {
        setTitle('. Por favor espere un poco más');
      }, 3000);

      // returned function will be called on component unmount
      return () => {
        clearTimeout(timer);
      };
    } else {
      const timer1 = setTimeout(() => {
        setTitle('. Espere un poco más');
      }, 4000);

      const timer2 = setTimeout(() => {
        setTitle('...');
      }, 10000);

      const timer = setTimeout(() => {
        setTitle(', cerrando sesión ');
        window.location = '/logout';
      }, 20000);

      return () => {
        clearTimeout(timer2);
        clearTimeout(timer1);
        clearTimeout(timer);
      };
    }
  }, [startDate, lbl]);

  return (
    <>
      <br />
      <br />
      <center className="mt-5">
        <AnimationComponent type="loading-2" w={350} h={350} loop={true} />
        <br />
        <br />
        Obteniendo información {title} ...
      </center>
      {props.children}
    </>
  );
};

const startDate = new Date();

const BasePage = (props) => {
  const [arrRoutes, setArrRoutes] = useState([]);

  const { role, privileges } = props;

  const {
    requestCompanyById,

    requestUsers,
    requestGroups,
    company,

    //users,
  } = props;

  const { statusFetch } = company;

  useEffect(() => {
    if (role !== 'undefined') {
      if (statusFetch === 'initial') {
        requestCompanyById();
        requestUsers();
        requestGroups();
      }
      setArrRoutes(getRoutes(privileges));
    } else {
      return () => setArrRoutes([]);
    }
  }, [
    role,
    statusFetch,
    privileges,
    requestCompanyById,
    requestUsers,
    requestGroups,
    setArrRoutes,
  ]); //requestUsers, ]);

  if (
    arrRoutes.length === 0 ||
    company.statusFetch === 'initial'
    //|| users.statusFetch === 'initial'
  ) {
    return (
      <Loading
        startDate={startDate}
        lbl={company.statusFetch /*+ users.statusFetch*/}
      />
    );
  }

  const master = sessionStorage.getItem('master');
  if (!master) {
    const defaultMaster = company.info.masters[0]?.id;
    sessionStorage.setItem('master', defaultMaster);
  }

  return (
    <Suspense fallback={<Loading startDate={new Date()} />}>
      <ScrollToTop>
        <Routes>
          {arrRoutes
            //.filter((i, i2) => i2 < 4)
            .map((item, index) => {
              return (
                <Route
                  key={'k2' + index}
                  path={item.path + (item.path.indexOf(':') > 0 ? '' : '/*')}
                  element={<item.component />}
                />
              );
            })}

          <Route
            path="/"
            element={<Navigate replace to={arrRoutes[0].path} />}
          />

          <Route element={<Navigate replace to={'/dashboard'} />} />
        </Routes>
      </ScrollToTop>
    </Suspense>
  );
};
export default connect(
  ({
    user,
    company,
    //  users
  }) => {
    return {
      role: user.role,
      privileges: user.privileges,
      company,
      //users: users,
    };
  },
  {
    ...authRedux.actions,
    ...companyRedux.actions,
    ...usersRedux.actions,
    ...groupsRedux.actions,
  }
)(BasePage);
