import { put, takeLatest } from 'redux-saga/effects';

import { getOrdersAsync, updateOrderAsync } from './ordersCrud';
export const actionTypes = {
  GetOrders: 'GET__ORDERS',
  GetOrdersCompleted: 'GET__ORDERS_COMPLETED',
  SetStatusOrders: 'SET__STATUS__ORDERS',


  UpdateOrder: 'UPDATE__ORDER',
  UpdateOrderCompleted: 'UPDATE__ORDER_COMPLETED',
  SetStatusUpdateOrders: 'UPDATE__STATUS__ORDER',
};

// #####################################################
// #####################################################

const initialCompanyState = {
  statusFetch: 'initial',
  list: {
    data: [],
  },
  last_order: {}
};

// #####################################################
// #####################################################

export const reducer = /* persistReducer(
  { storage: storageSession, key: 'cl-orders', whitelist: ['list'] },*/
  (state = initialCompanyState, action) => {
    switch (action.type) {
      case actionTypes.SetStatusOrders: {
        const { status } = action.payload;

        return {
          ...state,
          statusFetch: status,
        };
      }

      case actionTypes.GetOrdersCompleted: {
        const { orders } = action.payload;
        return {
          ...state,
          list: {data: orders.data},
          statusFetch: 'success',
        };
      }


      case actionTypes.UpdateOrderCompleted: {
        const { order } = action.payload;
        return {
          ...state,
          order: order.data,
          //statusFetch: 'success',
        };
      }

      default:
        return state;
    }
  }
//);

// #####################################################
// #####################################################

export const actions = {
  // ------------------------------------------------------------
  requestOrders: () => {
    return { type: actionTypes.GetOrders };
  },
  requestOrdersCompleted: function (orders) {
    return { type: actionTypes.GetOrdersCompleted, payload: { orders } };
  },
  setStatusOrders: (status) => {
    return { type: actionTypes.SetStatusOrders, payload: { status } };
  },

  // ------------------------------------------------------------

  requestUpdateOrders: (order) => {
    return { type: actionTypes.UpdateOrder, payload: { order }  };
  },
  requestUpdateOrderCompleted: function (order) {
    return { type: actionTypes.UpdateOrderCompleted, payload: { order } };
  },
  setStatusUpdateOrder: (status) => {
    return { type: actionTypes.SetStatusUpdateOrders, payload: { status } };
  },
};

// #####################################################
// #####################################################

export function* saga() {
  //listo order
  yield takeLatest(actionTypes.GetOrders, function* getOrdersSaga(action) {
    yield put(actions.setStatusOrders('initial'));
    try {
      const orders = yield getOrdersAsync();
      yield put(actions.requestOrdersCompleted(orders));
    } catch {
      yield put(actions.setStatusOrders('fail'));
    }
  });

  //--update order
  yield takeLatest(actionTypes.UpdateOrder, function* getUpdateOrderSaga(data) {
    yield put(actions.setStatusUpdateOrder('initial'));
    try {
      const order = yield updateOrderAsync(data.payload.order);
      yield put(actions.requestUpdateOrderCompleted(order));
    } catch {
      yield put(actions.setStatusUpdateOrder('fail'));
    }
  });
}


