import { put, takeLatest } from 'redux-saga/effects';
import {
  getTransferencesAsync,
  debitAsync,
  depositAsync,
  getOrderAsync
} from './transferencesCrud';

export const actionTypes = {
  GetTransferences: 'GET__TRANSFERENCE',
  GetTransferencesCompleted: 'GET__TRANSFERENCES_COMPLETED',
  SetStatusTransferences: 'SET__STATUS__TRANSFERENCES',

  DepositTransference: 'DEPOSIT__TRANSFERENCE',
  DepositTransferenceCompleted: 'DEPOSIT__TRANSFERENCE_COMPLETED',
  SetStatusDepositTransference: 'SET__STATUS__DEPOSIT_TRANSFERENCE',

  DebitTransference: 'DEBIT__TRANSFERENCE',
  DebitTransferenceCompleted: 'DEBIT__TRANSFERENCE_COMPLETED',
  SetStatusDebitTransference: 'SET__STATUS__DEBIT_TRANSFERENCE',


  ResetDepositTransference: 'RESET__DEPOSIT_TRANSFERENCE',

  GetOrder: 'GET__ORDER',
  GetOrderCompleted: 'GET__ORDER_COMPLETED',
  SetStatusOrder: 'SET__STATUS__ORDER',

};

// #####################################################
// #####################################################

const initialTransferencesState = {
  statusFetch: 'initial',
  list: {
    data: [],
  },

  depositStatus: 'initial',
  lastDeposit: null,

  debitStatus: 'initial',
  lastDebit: null,

  order: null,
  orderStatus:'initial'
};

// #####################################################
// #####################################################

export const reducer =
  /*persistReducer(
  { storage: storageSession, key: 'cl-transferences', whitelist: ['list'] },*/
  (state = initialTransferencesState, action) => {
    switch (action.type) {
      case actionTypes.SetStatusTransferences: {
        const { status } = action.payload;

        return {
          ...state,
          statusFetch: status,
        };
      }

      case actionTypes.GetTransferencesCompleted: {
        const { transferences } = action.payload;
        return {
          ...state,
          list: transferences.data,
          statusFetch: 'success',
        };
      }

      case actionTypes.DepositTransferenceCompleted: {
        const { transference } = action.payload;
        return {
          ...state,
          lastDeposit: transference.data,
          order: transference.data,
          depositStatus: 'success',
          //statusFetch: 'success',
        };
      }
      case actionTypes.SetStatusDepositTransference: {
        const { depositStatus } = action.payload;

        return {
          ...state,
          order:null,
          depositStatus,
        };
      }

      case actionTypes.SetStatusDebitTransference: {
        const { debitStatus } = action.payload;

        return {
          ...state,
          order:null,
          debitStatus
        };
      }


      case actionTypes.DebitTransferenceCompleted: {
        const { transference } = action.payload;
        return {
          ...state,
          lastDebit: transference.data,
          order: transference.data,
          debitStatus: 'success',
        };
      }

      case actionTypes.ResetDepositTransference : {
        return  initialTransferencesState;
      }


      case actionTypes.SetStatusOrder: {
        const { status } = action.payload;

        return {
          ...state,
          orderStatus: status,
        };
      }

      case actionTypes.GetOrderCompleted: {
        const { order } = action.payload;
        return {
          ...state,
          order: order.data || state.order,
          statusFetch: order.data? 'success' : 'fail',
        };
      }


      default:
        return state;
    }
  };
//);

// #####################################################
// #####################################################

export const actions = {
  // ------------------------------------------------------------
  requestTransferences: () => {
    return { type: actionTypes.GetTransferences };
  },
  requestTransferencesCompleted: function (transferences) {
    return {
      type: actionTypes.GetTransferencesCompleted,
      payload: { transferences },
    };
  },
  setStatusTransferences: (status) => {
    return { type: actionTypes.SetStatusTransferences, payload: { status } };
  },
  // ------------------------------------------------------------

  requestDepositTransferences: (transference) => {
    return { type: actionTypes.DepositTransference, payload: { transference } };
  },
  requestDepositTransferenceCompleted: function (transference) {
    return {
      type: actionTypes.DepositTransferenceCompleted,
      payload: { transference },
    };
  },
  setStatusDepositTransference: (depositStatus) => {
    return {
      type: actionTypes.SetStatusDepositTransference,
      payload: { depositStatus },
    };
  },

  // ------------------------------------------------------------

  requestDebitTransferences: (transference) => {
    return { type: actionTypes.DebitTransference, payload: { transference } };
  },
  requestDebitTransferenceCompleted: function (transference) {
    return {
      type: actionTypes.DebitTransferenceCompleted,
      payload: { transference },
    };
  },
  setStatusDebitTransference: (debitStatus) => {
    return {
      type: actionTypes.SetStatusDebitTransference,
      payload: { debitStatus },
    };
  },


  //
  resetTransference: () => {
    return {
      type: actionTypes.ResetDepositTransference,
      
    };
  },

   // ------------------------------------------------------------
   requestOrder: (order) => {
    return { type: actionTypes.GetOrder , payload: {order}};
  },
  requestOrderCompleted: function (order) {
    return {
      type: actionTypes.GetOrderCompleted,
      payload: { order },
    };
  },
  setStatusOrder: (status) => {
    return { type: actionTypes.SetStatusOrder, payload: { status } };
  },
  // ------------------------------------------------------------


};

// #####################################################
// #####################################################

export function* saga() {
  //listo transferences
  yield takeLatest(
    actionTypes.GetTransferences,
    function* getTransferencesSaga(action) {
      yield put(actions.setStatusTransferences('initial'));
      
      try {
        const transferences = yield getTransferencesAsync();
        yield put(actions.requestTransferencesCompleted(transferences));
      } catch {
        yield put(actions.setStatusTransferences('fail'));
      }
    }
  );

  //--Deposit
  yield takeLatest(
    actionTypes.DepositTransference,
    function* getDepositTransferenceSaga(data) {
      yield put(actions.setStatusDepositTransference('initial'));
      
      try {
        const transference = yield depositAsync(data.payload.transference);
        yield put(actions.requestDepositTransferenceCompleted(transference));
      } catch {
        yield put(actions.setStatusDepositTransference('fail'));
      }
    }
  );

  //--Debit
  yield takeLatest(
    actionTypes.DebitTransference,
    function* getDebitTransferenceSaga(data) {
      yield put(actions.setStatusDebitTransference('initial'));
      try {
        const transference = yield debitAsync(data.payload.transference);
        yield put(actions.requestDebitTransferenceCompleted(transference));
      } catch {
        yield put(actions.setStatusDebitTransference('fail'));
      }
    }
  );


   //listo transferences
   yield takeLatest(
    actionTypes.GetOrder,
    function* getOrderSaga(action) {
      yield put(actions.setStatusOrder('initial'));
      
      try {
        const order = yield getOrderAsync(action.payload);
        yield put(actions.requestOrderCompleted(order));
      } catch {
        yield put(actions.setStatusOrder('fail'));
      }
    }
  );
}
