import {
    getServices,
    getService,
    createService,
} from '../../../provider/api/core';

export function getServicesAsync(params) {
    return getServices(params);
}

export function getServiceAsync(service) {
    return getService(service);
}

export function createServiceAsync(params) {
    return createService(params);
}


