import { put, takeLatest } from 'redux-saga/effects';

import { getDashboardSummaryAsync } from './dashboardCrud';

import { transactionsSummary } from '../../Transactions/services/summary';

export const actionTypes = {
  GetDashboardSummary: 'GET__DASHBOARD_SUMMARY',
  GetDashboardSummaryCompleted: 'GET__DASHBOARD_SUMMARY_COMPLETED',
  SetStatusDashboardSummary: 'SET__STATUS_DASHBOARD_SUMMARY',

  SetDashboardFilterDate: 'SET__DASHBOARD_FILTER_DATE',

  DeleteDashboardSummary: 'DELETE__DASHBOARD_SUMMARY',
  // SetCardsFilterSortBy: 'SET__CARDS__FILTER_SORTBY',
  // SetCardsFilterSearchBy: 'SET__CARDS__FILTER_SEARCHBY',
};

// #####################################################
// #####################################################

const initialCompanyState = {
  statusFetch: 'initial',

  month: {
    debit: 0,
    deposit: 0,
    operations: 0,
  },
  summary: {
    debit: 0,
    deposit: 0,
    operations: 0,
    category: {},
    cards: {},
    merchants: {},
    status: {},
    dates: {},
    initial_amount: 0,
    final_amount: 0,
    transactions: [],
  },

  today: {
    date: '',
    debit: 0,
    deposit: 0,
    operations: 0,
    transactions: [],
  },

  filterByDate: new Date().toISOString().substring(0, 7) + '-01',
};

// #####################################################
// #####################################################

export const reducer = /*persistReducer(
  { storage: storageSession, key: 'cl-dashboard'},// whitelist: ['summary'] },
  */ (state = initialCompanyState, action) => {
    switch (action.type) {
      case actionTypes.SetStatusDashboardSummary: {
        const { status } = action.payload;

        return {
          ...state,
          statusFetch: status,
        };
      }

      case actionTypes.GetDashboardSummaryCompleted: {
        const { summary } = action.payload;

        const response = transactionsSummary(state.filterByDate, summary.data);

        const thisMonth =
          new Date().toISOString().substr(0, 7) ===
          response.start_date.substr(0, 7);
        const month = {
          debit: response.debit,
          deposit: response.deposit,
        };
        return {
          ...state,
          summary: response,
          today: response.today || state.today,
          month: thisMonth ? month : state.month,
          statusFetch: 'success',
        };
      }

      case actionTypes.SetDashboardFilterDate: {
        const { filterByDate } = action.payload;

        return {
          ...state,
          filterByDate: filterByDate,
        };
      }

      case actionTypes.DeleteDashboardSummary: {
        return initialCompanyState;
      }

      default:
        return state;
    }
  };
//);

// #####################################################
// #####################################################

export const actions = {
  // ------------------------------------------------------------
  requestDashboardSummary: (params) => {
    return { type: actionTypes.GetDashboardSummary, payload: { params } };
  },
  requestDashboardSummaryCompleted: function (summary) {
    return {
      type: actionTypes.GetDashboardSummaryCompleted,
      payload: { summary },
    };
  },
  setStatusDashboardSummary: (status) => {
    return { type: actionTypes.SetStatusDashboardSummary, payload: { status } };
  },

  setDashboardFilterDate: (filterByDate) => {
    return {
      type: actionTypes.SetDashboardFilterDate,
      payload: { filterByDate },
    };
  },

  requestDeleteDashboardSummary: () => {
    return {
      type: actionTypes.DeleteDashboardSummary,
      payload: {},
    };
  }
};

// #####################################################
// #####################################################

export function* saga() {
  yield takeLatest(
    actionTypes.GetDashboardSummary,
    function* getDashboardSummarySaga(action) {
      yield put(actions.setStatusDashboardSummary('initial'));
      try {
        const response = yield getDashboardSummaryAsync(action.payload);
        yield put(actions.requestDashboardSummaryCompleted(response));
      } catch {
        yield put(actions.setStatusDashboardSummary('fail'));
      }
    }
  );
}
