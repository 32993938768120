import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';

import config from './app_config';

const userPoolId = config.cognito.userPoolId;
const clientId = config.cognito.clientId;
const region = config.cognito.region;

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
  Region: `${region}`,
  storage: window.sessionStorage

};

export const getUserPool = () => new CognitoUserPool(poolData);
let userPool = getUserPool();
let currentUser = null;
export const getCurrentUser = () => {
  return userPool.getCurrentUser();
};

export const getCognitoUser = (username) => {
  const userData = {
    Username: username,
    Pool: userPool,
    Storage: window.localStorage,
  };
  const cognitoUser = new CognitoUser(userData);

  return cognitoUser;
};

// SigninWithEmail
export const signInWithEmail = async (username, password) => {
  return new Promise(function (resolve, reject) {
    let authenticationData = {
      Username: username,
      Password: password,
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);

    let userPool = new CognitoUserPool(poolData);
    let userData = {
      Username: username,
      Pool: userPool,
    };
    let cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        resolve(result);
      },

      onFailure: function (err) {
        reject(err);
      },

      newPasswordRequired: function (userAttributes, requiredAttributes) {
        if (userAttributes.email_verified) delete userAttributes.email_verified;
        if (userAttributes.phone_number_verified)
          delete userAttributes.phone_number_verified;
        if (userAttributes.phone_number) delete userAttributes.phone_number;
        userAttributes = {};
        currentUser.completeNewPasswordChallenge(
          password,
          userAttributes,
          this
        );
      },
    });
  }).catch((err) => {
    throw err;
  });
};

//test
export const signUp = ({ name, email, phone, password }) => {
  let attr = [
    new CognitoUserAttribute({ Name: 'email', Value: email }),
    new CognitoUserAttribute({ Name: 'phone_number', Value: phone }),
  ];

  return new Promise((resolve, reject) => {
    userPool.signUp(
      email,
      password,
      attr,
      null,
      function signUpCallback(err, result) {
        if (!err) {
          resolve(result);
        } else {
          reject(err);
        }
      }
    );
  });
};

export const forgotPassword = async ({ email }) => {
  let cognitoUser = getCognitoUser(email);

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
    });
  });
};

export const confirmPassword = async ({ email, code, password }) => {
  let cognitoUser = getCognitoUser(email);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, password, {
      onSuccess: resolve,
      onFailure: reject,
    });
  });
};

export const changePassword = async ({ oldPassword, newPassword }) => {
  await getSession();
  return new Promise((resolve, reject) => {
    currentUser.changePassword(oldPassword, newPassword, (err, result) => {
      if (!err) resolve(result);
      else reject(err);
    });
  });
};

//SignOut
export const signOut = async () => {
  try {
    await getSession();
  } catch (ex) {
    console.log(ex);
  }
  return new Promise(function (resolve, reject) {
    if (currentUser) {
      currentUser.globalSignOut({
        onSuccess: () => {
          currentUser.signOut();
          resolve({ data: { success: true } });
        },
        onFailure: (err) => {
          currentUser.signOut();
          resolve({ data: { success: false } });
        },
      });
    }
  });
};

/*
export const getCognitoUser = user => {
  const pool = getUserPool()
  return pool.getCurrentUser()
}
*/

export const getSession = async () => {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise(function (resolve, reject) {
    if (!currentUser) {
      reject();
      return;
    }
    currentUser.getSession(function (err, session) {
      if (err || !session.isValid()) {
        reject(err);
      } else {
        resolve(session);
      }
    });
  }).catch((err) => {
    throw err;
  });
};
